import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col, Container } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { saveForumThreadAnswer } from "redux/actions/forumActions";
import moment from "moment/moment";
import { getUserName } from "utils/authHelper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageUploader from "quill-image-uploader";
import ImageCompress from "quill-image-compress";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageCompress", ImageCompress);

function ForumFormAnswer({
  onCancel,
  onEditAnswer,
  form,
  onSetForm,
  userInfo,
  threadId,
}) {
  const [errors, setErrors] = useState({});
  const reactQuillRef = useRef(null);

  const distpatch = useDispatch();

  const onChange = (event) => {
    const { name, value } = event.target;
    onSetForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSave = (e) => {
    e.preventDefault();
    const { userId } = userInfo;
    form.threadId = threadId;
    form.authorId = userId;
    form.authorName = getUserName(userInfo);
    form.published = new Date();
    console.log(form);
    distpatch(saveForumThreadAnswer(form));
    onCancel();
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["code-block"],
        ["clean"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 600, // default
      maxHeight: 800, // default
      imageType: "*", // default
      debug: true, // default
      suppressErrorLogging: false, // default
      handleOnPaste: true, //default
      insertIntoEditor: true, // default
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  return (
    <Container fluid>
      <Form onSubmit={onSave}>
        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="answer">
              <Form.Label>Twoja odpowiedź</Form.Label>
              <ReactQuill
                theme="snow"
                ref={reactQuillRef}
                required
                value={form?.answer}
                onChange={(e) =>
                  onChange({ target: { name: "answer", value: e } })
                }
                modules={modules}
                formats={formats}
              />
              {/* <Form.Control
                as="textarea"
                rows={4}
                placeholder="Wprowadź treść odpowiedzi"
                required
                name="answer"
                onChange={onChange}
                value={form?.answer}
              /> */}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group
              className="mb-3 buttons mgt-12"
              style={{ gridTemplateColumns: "1fr 1fr" }}
              controlId="actions"
            >
              <Button variant="dark" type="button" onClick={onCancel}>
                Anuluj
              </Button>
              <Button variant="primary" type="submit">
                Zapisz
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ForumFormAnswer;
