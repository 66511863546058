import { BsFillLightningChargeFill } from "react-icons/bs";

function ComputePace({ onUserPersonalBestChange }) {
  return (
    <BsFillLightningChargeFill
      onClick={onUserPersonalBestChange}
      style={{
        alignSelf: "center",
        width: "20px",
        height: "20px",
        marginLeft: "6px",
        cursor: "pointer",
      }}
    />
  );
}

export default ComputePace;
