import { useState } from "react";

function ForumSearch({ onHandleForumSearch }) {
  const [searchValue, setSearchValue] = useState("");

  function search(e) {
    setSearchValue(e.target.value);
    onHandleForumSearch(e.target.value);
  }

  return (
    <span className="input-icon input-icon-sm ml-auto w-auto">
      <input
        type="text"
        className="form-control form-control-sm bg-gray-200 border-gray-200 shadow-none mb-4 mt-4"
        placeholder="Przeszukaj forum"
        value={searchValue}
        onInput={search}
      />
    </span>
  );
}

export default ForumSearch;
