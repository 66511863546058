import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./confirmationModalStyles.css";

const ConfirmationModal = ({
  okLabel = "Potwierdź",
  cancelLabel = "Anuluj",
  title = "Usuwanie całego wątku",
  confirmation = "Czy jesteś pewien, że chcesz usunąć cały wątek forum. Zmiany są nieodwracalne!",
  showConfirmModal,
  onSetShowConfirmModal,
  enableEscape = true,
  action,
}) => {
  return (
    <Modal
      show={showConfirmModal}
      onHide={() => onSetShowConfirmModal(false)}
      backdrop={enableEscape ? true : "static"}
      keyboard={enableEscape}
      ariaHideApp={false}
      backdropColor={"white"}
      backdropOpacity={2}
      animationIn={"slideInLeft"}
      animationOut={"slideOutRight"}
    >
      <Modal.Header
        style={{ paddingTop: "0px", borderBottom: "1px solid #dee2e6" }}
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmation}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onSetShowConfirmModal(false)}
          variant="secondary"
        >
          {cancelLabel}
        </Button>
        <Button className="button-l" variant="danger" onClick={action}>
          {okLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
