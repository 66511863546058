import {
    Button
  } from "react-bootstrap";
import propTypes from "prop-types";
import React, { useState } from "react";
import './selectButtonStyles.css';

function SelectButton({onClick, isSelected}) {

    const handleOnClick = () => {
        onClick();
    }

    return (
            <>
            { isSelected? <Button variant="success" onClick={handleOnClick}><i className="nc-icon nc-check-2 icon-check"></i>Wybrano</Button> :
            <Button variant="primary" onClick={handleOnClick}>Wybierz</Button>
            }
            
            </>
    )
}

export default SelectButton
