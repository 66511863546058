import * as types from "../actions/actionTypes";
import initialState from "./initialState";

function sortForumData(threads, sortBy) {
  switch (sortBy) {
    case "watched":
      return [...threads].sort((a, b) => b.watched - a.watched);
    case "answered":
      return [...threads].sort((a, b) => a.answered - b.answered);
    case "published":
      return [...threads].sort((a, b) => b.published - a.published);
    default:
      return threads;
  }
}

export default function forumReducer(state = initialState.forum, action) {
  switch (action.type) {
    case types.LOAD_FORUM_THREADS_SUCCESS:
      return {
        ...state,
        allThreads: action.threads,
        threads: action.threads,
      };
    case types.FORUM_LOAD_THREAD_ANSWERS_SUCCESS:
      return {
        ...state,
        threadAnswers: action.answers,
      };
    case types.LOAD_FORUM_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
      };
    case types.ADD_FORUM_THREAD_SUCCESS:
      return {
        ...state,
        threads: [...state.threads, action.thread],
        allThreads: [...state.threads, action.thread],
      };
    case types.FILTER_FORUM_THREADS_BY_CATEGORY:
      return {
        ...state,
        threads:
          action.categoryId === 0
            ? state.allThreads
            : state.allThreads.filter(
                (item) => item.categoryId === action.categoryId
              ),
      };
    case types.SEARCH_FORUM:
      return {
        ...state,
        threads: !action.text
          ? state.allThreads
          : state.allThreads.filter(
              (item) =>
                item.title
                  .toLocaleLowerCase()
                  .includes(action.text.toLocaleLowerCase()) ||
                item.description
                  .toLocaleLowerCase()
                  .includes(action.text.toLocaleLowerCase()) ||
                item.authorName
                  .toLocaleLowerCase()
                  .includes(action.text.toLocaleLowerCase())
            ),
      };
    case types.SORT_FORUM_THREADS:
      return {
        ...state,
        threads: sortForumData(state.threads, action.sortBy),
      };
    case types.DELETE_FORUM_THREAD:
      return {
        ...state,
        threads: state.threads.filter((item) => item.id !== action.threadId),
      };
    case types.FORUM_DELETE_THREAD_ANSWER:
      return {
        ...state,
        threadAnswers: state.threadAnswers.filter(
          (item) => item.id !== action.answerId
        ),
      };
    case types.FORUM_SAVE_THREAD_ANSWER_SUCCESS: {
      return {
        ...state,
        threads: state.threads.map((item) =>
          item.id === action.answer.threadId
            ? { ...item, answered: item.answered + 1 }
            : item
        ),
        allThreads: state.threads.map((item) =>
          item.id === action.threadId
            ? { ...item, answered: item.answer.answered + 1 }
            : item
        ),
        threadAnswers: [...state.threadAnswers, action.answer],
      };
    }
    case types.UPDATE_FORUM_THREAD_SUCCESS:
      return {
        ...state,
        threads: state.threads.map((i) =>
          i.id === action.thread.id ? action.thread : { ...i }
        ),
        allThreads: state.threads.map((i) =>
          i.id === action.thread.id ? action.thread : { ...i }
        ),
      };
    case types.FORUM_UPDATE_THREAD_ANSWER_SUCCESS:
      return {
        ...state,
        threadAnswers: state.threadAnswers.map((i) =>
          i.id === action.answer.id
            ? { ...i, answer: action.answer.answer }
            : { ...i }
        ),
      };
    case types.FORUM_UI_GO_TO_MAIN_VIEW:
      return {
        ...state,
        ui: {
          mainView: true,
        },
      };
    case types.FORUM_UI_GO_TO_THREAD_VIEW:
      return {
        ...state,
        ui: {
          mainView: false,
        },
      };
    default:
      return state;
  }
}
