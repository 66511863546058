import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function exercisePlans(
  state = initialState.exercisePlans,
  action
) {
  switch (action.type) {
    case types.LOAD_EXERCISE_PLANS_SUCCESS:
      return {
        ...state,
        data: action.exercisePlans,
      };
    case types.SELECT_EXERCISE_PLAN:
      return {
        ...state,
        selectedPlans: state.selectedPlans.filter(
          (item) => item.id !== action.selectedPlanId
        ),
        data: state.data.map((item) =>
          item.id === action.selectedPlanId
            ? { ...item, selected: !item.selected }
            : { ...item }
        ),
      };
    case types.LOAD_SELECTED_EXERCISES_PLANS_SUCCESS:
      return {
        ...state,
        selectedPlans: action.selectedPlans,
      };
    case types.LOAD_EXERCISES_SUCCESS:
      return {
        ...state,
        exercises: action.exercises,
      };
    case types.SET_SELECTED_EXERCISE_ID:
      return {
        ...state,
        selectedExercisePlanId: action.exercisePlanId,
      };
    default:
      return state;
  }
}
