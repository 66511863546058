import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/training/plan";
// const baseUrl = "http://localhost:8080/trainings";

export function getTrainings(userId) {
  return fetch(baseUrl + "/user/" + userId, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addTraining(training) {
  return fetch(baseUrl, {
    method: training.id ? "PUT" : "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(training),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteTraining(trainingId) {
  return fetch(baseUrl + "/" + trainingId, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
