export const RESET_APP = "RESET_APP";

export const ADD_TRAINING = "ADD_TRAINING";
export const LOAD_TRAININGS_SUCCESS = "LOAD_TRAININGS_SUCCESS";
export const LOAD_EXERCISE_PLANS_SUCCESS = "LOAD_EXERCISE_PLANS_SUCCESS";
export const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const DELETE_TRAINING_OPTIMISTIC = "DELETE_TRAINING_OPTIMISTIC";
export const LOGIN = "auth/LOGIN";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "auth/LOGIN_FAILURE";
export const LOGOUT = "auth/LOGOUT";

export const LOAD_USER_PROFILE_SUCCESS = "user/LOAD_USER_PROFILE_SUCCESS";
export const LOAD_USER_DETAILS_SUCCESS = "user/LOAD_USER_DETAILS_SUCCESS";
export const LOAD_USER_TRAINING_INFORMATION_SUCCESS =
  "user/LOAD_USER_TRAINING_INFORMATION_SUCCESS";
export const LOAD_USER_PERSONAL_BEST_SUCCESS =
  "user/LOAD_USER_PERSONAL_BEST_SUCCESS";
export const UPDATE_USER_PROFILE = "user/UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "user/UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_DETAILS = "user/UPDATE_USER_PROFILE";
export const UPDATE_USER_DETAILS_SUCCESS = "user/UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_TRAINING_INFO = "user/UPDATE_TRAINING_INFO";
export const UPDATE_USER_TRAINING_INFO_SUCCESS =
  "user/UPDATE_TRAINING_INFO_SUCCESS";
export const UPDATE_USER_PERSONAL_BEST = "user/UPDATE_PERSONAL_BEST";
export const UPDATE_USER_PERSONAL_BEST_SUCCESS =
  "user/UPDATE_PERSONAL_BEST_SUCCESS";
export const LOAD_COACH_USERS_SUCCESS = "user/LOAD_COACH_USERS_SUCCESS";
export const SET_USER = "user/SET_USER";

export const SELECT_EXERCISE_PLAN = "exercise/SELECT_EXERCISE_PLAN";
export const SET_SELECTED_EXERCISES_PLANS =
  "exercise/SET_SELECTED_EXERCISES_PLANS";
export const LOAD_SELECTED_EXERCISES_PLANS_SUCCESS =
  "exercise/LOAD_SELECTED_EXERCISES_PLANS_SUCCESS";

export const LOAD_EXERCISES_SUCCESS = "exercise/LOAD_EXERCISES_SUCCESS";
export const SET_SELECTED_EXERCISE_ID = "exercise/SET_SELECTED_EXERCISE_ID";

export const LOAD_ACTIVITY_TYPES = "dictionaries/LOAD_ACTIVITY_TYPES";
export const LOAD_ACTIVITY_TYPES_SUCCESS =
  "dictionaries/LOAD_ACTIVITY_TYPES_SUCCESS";

export const SET_DATE = "core/SET_DATE";

export const REGISTER_USER = "auth/REGISTER_USER";
export const REGISTER_USER_SUCCESS = "auth/REGISTER_USER_SUCCESS";

export const CHANGE_USER_PASSWORD = "auth/CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "auth/CHANGE_USER_PASSWORD_SUCCESS";

export const LOAD_FORUM_THREADS_SUCCESS = "forum/LOAD_FORUM_THREADS_SUCCESS";
export const LOAD_FORUM_CATEGORIES_SUCCESS =
  "forum/LOAD_FORUM_CATEGORIES_SUCCESS";

export const ADD_FORUM_THREAD = "forum/ADD_FORUM_THREAD";
export const DELETE_FORUM_THREAD = "forum/DELETE_FORUM_THREAD";
export const ADD_FORUM_THREAD_SUCCESS = "forum/ADD_FORUM_THREAD_SUCCESS";
export const UPDATE_FORUM_THREAD_SUCCESS = "forum/UPDATE_FORUM_THREAD_SUCCESS";
export const FILTER_FORUM_THREADS_BY_CATEGORY =
  "forum/FILTER_FORUM_THREADS_BY_CATEGORY";
export const SEARCH_FORUM = "forum/SEARCH_FORUM";
export const SORT_FORUM_THREADS = "forum/SORT_FORUM_THREADS";
export const FORUM_UI_GO_TO_MAIN_VIEW = "forum/FORUM_UI_GO_TO_MAIN_VIEW";
export const FORUM_UI_GO_TO_THREAD_VIEW = "forum/FORUM_UI_GO_TO_THREAD_VIEW";
export const FORUM_LOAD_THREAD_ANSWERS_SUCCESS =
  "forum/FORUM_LOAD_THREAD_ANSWERS_SUCCESS";
export const FORUM_SAVE_THREAD_ANSWER_SUCCESS =
  "forum/FORUM_SAVE_THREAD_ANSWER_SUCCESS";
export const FORUM_UPDATE_THREAD_ANSWER_SUCCESS =
  "forum/FORUM_UPDATE_THREAD_ANSWER_SUCCESS";
export const FORUM_DELETE_THREAD_ANSWER = "forum/FORUM_DELETE_THREAD_ANSWER";

export const GET_STRAVA_TOKEN = "strava/GET_STRAVA_TOKEN";
export const GET_STRAVA_TOKEN_SUCCESS = "strava/GET_STRAVA_TOKEN_SUCCESS";

export const SAVE_STRAVA_TOKEN = "strava/SAVE_STRAVA_TOKEN";
export const SAVE_STRAVA_TOKEN_SUCCESS = "strava/SAVE_STRAVA_TOKEN_SUCCESS";
