import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import propTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import LoginPage from "../components/LoginPage/LoginPage";
import "react-toastify/dist/ReactToastify.css";
import { checkIfExistingTokenIsValid } from "utils/authHelper";
import { resetApp } from "../redux/actions/globalActions";
import "bootstrap/dist/css/bootstrap.min.css";
import RegisterForm from "./LoginPage/RegisterForm";

function App({ isAuthenticated, resetApp }) {
  useEffect(() => {
    resetApp();
  }, []);

  return (
    <div>
      {!isAuthenticated &&
      (window.location.href.includes("/kosekteam/register") ||
        window.location.href.includes("/kreskateam/register")) ? (
        <RegisterForm />
      ) : isAuthenticated || checkIfExistingTokenIsValid("token") ? (
        <Switch>
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
          <Redirect to="/" />
        </Switch>
      ) : (
        <LoginPage />
      )}
      <ToastContainer autoClose={3000} />
    </div>
  );
}

App.propTypes = {
  isAuthenticated: propTypes.bool.isRequired,
  resetApp: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

const mapDispatchToProps = {
  resetApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
