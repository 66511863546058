import * as types from "./actionTypes";
import * as exercisesApi from "../../api/exercisesAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadExercisePlansSuccess(exercisePlans) {
  return { type: types.LOAD_EXERCISE_PLANS_SUCCESS, exercisePlans };
}

export function loadSelectedExercisesPlansSuccess(selectedPlans) {
  return { type: types.LOAD_SELECTED_EXERCISES_PLANS_SUCCESS, selectedPlans };
}

export function selectExercisePlan(selectedPlanId) {
  return { type: types.SELECT_EXERCISE_PLAN, selectedPlanId };
}

export function setSelectedExercisePlanId(exercisePlanId) {
  return { type: types.SET_SELECTED_EXERCISE_ID, exercisePlanId };
}

export function loadExercisesSuccess(exercises) {
  return { type: types.LOAD_EXERCISES_SUCCESS, exercises };
}

export function loadExercisePlans(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return exercisesApi
      .getExercisePlans(userId)
      .then((exercisePlans) => {
        dispatch(loadExercisePlansSuccess(exercisePlans));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadExercisePlansByPlanId(planId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return exercisesApi
      .getExercisePlansByPlanId(planId)
      .then((exercisePlans) => {
        dispatch(loadExercisePlansSuccess(exercisePlans));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadExercises(exercisePlanId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return exercisesApi
      .getExercisesByExercisePlanId(exercisePlanId)
      .then((exercises) => {
        dispatch(loadExercisesSuccess(exercises));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function setSelectedPlans(planId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return exercisesApi
      .getSelectedExercisePlans(planId)
      .then((selectedPlans) => {
        dispatch(loadSelectedExercisesPlansSuccess(selectedPlans));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
