import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function trainingReducer(
  state = initialState.trainings,
  action
) {
  switch (action.type) {
    case types.ADD_TRAINING_SUCCESS:
      return [...state, { ...action.training }];
    case types.UPDATE_TRAINING_SUCCESS:
      return state.map((training) =>
        training.id === action.training.id ? action.training : training
      );
    case types.LOAD_TRAININGS_SUCCESS:
      return action.trainings;
    case types.DELETE_TRAINING_OPTIMISTIC:
      return state.filter((training) => training.id !== action.training.id);
    default:
      return state;
  }
}
