import { Card } from "react-bootstrap";
import ColumnInformator from "../ColumnInformator";
import TextBlock from "components/Shared/TextBlock/TextBlock";
import { CardBody } from "reactstrap";

const headerStyles = {
  textAlign: "center",
  fontFamily: "ui-monospace",
  fontWeight: "bold",
  color: "white",
};

const shortDescriptionStyles = {
  fontFamily: "system-ui",
  fontSize: "16px",
  textAlign: "center",
  fontStyle: "italic",
  color: "white",
};

const longDescriptionStyles = {
  fontFamily: "system-ui",
  fontSize: "16px",
  textAlign: "left",
  color: "white",
};

const columnIconStyle = {
  display: "flex",
  justifyContent: "space-around",
  color: "white",
};

function RichCard({ data, date }) {
  const getTrainingByDate = (data, date) => {
    return data.filter((item) => item.start === date)[0];
  };

  const trainingInfo = getTrainingByDate(data, date);

  return (
    <Card style={{ width: "18rem" }} bg="dark">
      <Card.Img
        variant="top"
        src={require(trainingInfo?.activityTypeId === 1
          ? "assets/img/bc1.jpg"
          : trainingInfo?.activityTypeId === 2
          ? "assets/img/bc2.jpg"
          : trainingInfo?.activityTypeId === 3
          ? "assets/img/WT.jpg"
          : trainingInfo?.activityTypeId === 4
          ? "assets/img/sprawnosc.jpg"
          : trainingInfo?.activityTypeId === 5
          ? "assets/img/exerciseImage.jpg"
          : trainingInfo?.activityTypeId === 6
          ? "assets/img/race.jpg"
          : trainingInfo?.activityTypeId === 7
          ? "assets/img/bnp.jpg"
          : trainingInfo?.activityTypeId === 11
          ? "assets/img/uphill.jpg"
          : "assets/img/rest.jpg")}
      />
      {trainingInfo === undefined || trainingInfo?.unit === null ? (
        <CardBody>
          <Card.Title style={headerStyles} as="h4">
            Wolne od biegania!
          </Card.Title>
          <Card.Text style={shortDescriptionStyles}>
            Na dzisiaj nie masz zaplanowanych żadnych treningów biegowych.
            Pamiętaj, aby zadbać o odpowiednią regenerację i skupić się na
            odpoczynku.
          </Card.Text>
        </CardBody>
      ) : (
        <Card.Body>
          <Card.Title style={headerStyles} as="h4">
            {trainingInfo?.title}
          </Card.Title>
          <Card.Text style={shortDescriptionStyles}>
            {trainingInfo?.description}
          </Card.Text>
          <Card.Text style={columnIconStyle}>
            <ColumnInformator
              icon="nc-square-pin"
              value={trainingInfo?.kilometers}
              standard={trainingInfo?.unit}
            />
            <ColumnInformator
              icon="nc-chart-bar-32"
              value={`${trainingInfo?.defaultDifficulty}/10`}
              standard="trudność"
            />
          </Card.Text>
          <Card.Text style={longDescriptionStyles}>
            <TextBlock text={trainingInfo?.longDescription} />
          </Card.Text>
        </Card.Body>
      )}
    </Card>
  );
}

export default RichCard;
