import React, { useState } from "react";
import propTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import "./loginPageStyles.css";
import { authenticationErrors } from "utils/exceptionsHelper";
import { useParams } from "react-router-dom";
import { isKosekTeam } from "utils/locationHelper";

const LoginForm = ({ onLogin, onChange, user, errors }) => {
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 text-black">
            <div className="px-5 ms-xl-4">
              <br></br>
              <span className="h1 fw-bold mb-0">
                Biegostrefa{" "}
                <img
                  width="64px"
                  height="64px"
                  src={require("assets/img/athletics.png")}
                />
              </span>
              <p
                style={{
                  fontFamily: "fangsong",
                  textTransform: "none",
                  fontSize: "28px",
                }}
              >
                {isKosekTeam() ? "Kosek Team" : "Kreska Team"}
              </p>
            </div>

            <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
              <Form style={{ width: "23rem" }} onSubmit={onLogin}>
                <h3
                  className="fw-normal mb-3 pb-3"
                  style={{ letterSpacing: "1px" }}
                >
                  Logowanie
                </h3>

                <Form.Group className="form-outline mb-4">
                  <Form.Control
                    type="email"
                    id="username"
                    name="username"
                    className="form-control form-control-lg"
                    onChange={onChange}
                    value={user?.username}
                  />
                  <Form.Label className="form-label" htmlFor="username">
                    Nazwa użytkownika
                  </Form.Label>
                </Form.Group>

                <Form.Group className="form-outline mb-4">
                  <Form.Control
                    type="password"
                    id="password"
                    name="password"
                    className="form-control form-control-lg"
                    onChange={onChange}
                    value={user?.password}
                  />
                  <Form.Label className="form-label" htmlFor="password">
                    Hasło
                  </Form.Label>
                </Form.Group>

                <Form.Group className="pt-1 mb-4">
                  <Button
                    className="btn btn-primary btn-lg btn-block"
                    variant="primary"
                    type="submit"
                  >
                    Zaloguj
                  </Button>
                </Form.Group>

                <Alert show={"onLogin" in errors} variant="danger">
                  {authenticationErrors(errors.onLogin)}
                </Alert>

                <p className="small mb-5 pb-lg-2">
                  {/* <a className="text-muted" href="#!">
                    Forgot password?
                  </a> */}
                </p>
                <p>
                  Nie posiadasz jeszcze konta?
                  <a
                    href={
                      isKosekTeam()
                        ? "/kosekteam/register"
                        : "/kreskateam/register"
                    }
                    className="link-info"
                  >
                    Zarejestruj się tutaj
                  </a>
                </p>
              </Form>
            </div>
          </div>
          <div className="col-sm-6 px-0 d-none d-sm-block">
            <img
              src={require(window.location.pathname === "/kosekteam" ||
                window.location.pathname === "/"
                ? "assets/img/pawelkosek_login.jpg"
                : "assets/img/login.jpg")}
              alt="Login image"
              style={{
                objectFit: "cover",
                objectPosition: "left",
                width: "81%",
                height: "100%",
              }}
            />
          </div>
        </div>
        {isKosekTeam() ? (
          <footer>
            <img
              src={require("assets/img/baner.png")}
              alt="Login image"
              style={{ width: "90%" }}
            />
          </footer>
        ) : null}
      </div>
    </section>
  );
};

LoginForm.propTypes = {
  user: propTypes.object.isRequired,
  onLogin: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
};

export default LoginForm;
