import { useState } from "react";

function TextBlock({ text, size = 20 }) {
  const [isTextVisible, setIsTextVisible] = useState(false);

  const contentStyles = {
    display: "grid",
    gap: "12px",
    fontSize: "16px",
  };

  const buttonStyles = {
    justifySelf: "end",
    marginRight: "12px",
    marginBottom: "12px",
    color: "#1dc7ea",
    cursor: "pointer",
  };

  function getText() {
    if (isTextVisible) {
      return text;
    } else {
      return text?.split(" ")?.slice(0, size).join(" ");
    }
  }

  return (
    <span style={contentStyles}>
      <span>{getText()}</span>
      <span
        onClick={() => setIsTextVisible((isVisible) => !isVisible)}
        style={buttonStyles}
      >
        {isTextVisible ? "Ukryj" : "Pokaż więcej"}
      </span>
    </span>
  );
}

export default TextBlock;
