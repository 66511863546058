import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadUserPersonalBest,
  updateUserPersonalBest,
} from "../../redux/actions/userActions";
import propTypes from "prop-types";
import UserPersonalBestForm from "./UserPersonalBestForm";
import Loading from "components/Shared/Loading";
import { toast } from "react-toastify";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";

export function userPersonalBestContainer({
  userPersonalBest,
  loadUserPersonalBest,
  loading,
  userId,
  updateUserPersonalBest,
}) {
  const [userPersonalBestState, setuserPersonalBestState] = useState({});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!IsNullOrEmptyOrUndefined(userId)) {
      loadUserPersonalBest(userId).catch((error) => {
        console.log("Load user personal best failed" + error);
      });
    }
  }, [userId]);

  useEffect(() => {
    setuserPersonalBestState(userPersonalBest);
  }, [userPersonalBest]);

  function calculatePace(time, distance) {
    const clearTime = time.includes("(")
      ? time.substring(0, time.indexOf("(") - 1)
      : time;

    const timeParts = clearTime.split(":").map(Number);
    let totalSeconds;

    if (timeParts.length === 3) {
      // Format "HH:MM:SS"
      const [hours, minutes, seconds] = timeParts;
      totalSeconds = hours * 3600 + minutes * 60 + seconds;
    } else if (timeParts.length === 2) {
      // Format "MM:SS"
      const [minutes, seconds] = timeParts;
      totalSeconds = minutes * 60 + seconds;
    } else {
      throw new Error(
        'Nieprawidłowy format czasu. Użyj "HH:MM:SS" lub "MM:SS".'
      );
    }

    const paceInSeconds = totalSeconds / distance;
    const paceMinutes = Math.floor(paceInSeconds / 60);
    const paceSeconds = Math.round(paceInSeconds % 60);

    // Formatuj sekundy jako dwucyfrową liczbę
    const formattedSeconds = paceSeconds < 10 ? "0" + paceSeconds : paceSeconds;
    console.log(`${paceMinutes}:${formattedSeconds}/km`);
    return `${paceMinutes}:${formattedSeconds}/km`;
  }

  function handleUserPersonalBest(event, distance = null, time = false) {
    console.log(event);
    const { name, value } = event.target;
    if (distance) {
      const clearValue = value.includes("(")
        ? value.substring(0, value.indexOf("(") - 1)
        : value;
      let computedValue =
        clearValue.split(":")[1]?.length > 1
          ? `${clearValue} (${calculatePace(clearValue, distance)})`
          : clearValue;
      setuserPersonalBestState((prevUserPersonalBest) => ({
        ...prevUserPersonalBest,
        [name]: time ? value : computedValue,
      }));
    } else {
      setuserPersonalBestState((prevUserPersonalBest) => ({
        ...prevUserPersonalBest,
        [name]: value,
      }));
    }
  }

  const handleUserPersonalBestSave = (e) => {
    e.preventDefault();
    setSaving(true);
    const userPersonalBestUpdate = {
      userId: userId,
      fiveKm: userPersonalBestState.fiveKm,
      fiveKmSeasonBest: userPersonalBestState.fiveKmSeasonBest,
      tenKm: userPersonalBestState.tenKm,
      tenKmSeasonBest: userPersonalBestState.tenKmSeasonBest,
      halfMarathon: userPersonalBestState.halfMarathon,
      halfMarathonSeasonBest: userPersonalBestState.halfMarathonSeasonBest,
      marathon: userPersonalBestState.marathon,
      marathonSeasonBest: userPersonalBestState.marathonSeasonBest,
      twentyFourKm: userPersonalBestState.twentyFourKm,
      twentyFourKmSeasonBest: userPersonalBestState.twentyFourKmSeasonBest,
      fourtyEightKm: userPersonalBestState.fourtyEightKm,
      fourtyEightKmSeasonBest: userPersonalBestState.fourtyEightKmSeasonBest,
      fiftyKm: userPersonalBestState.fiftyKm,
      fiftyKmSeasonBest: userPersonalBestState.fiftyKmSeasonBest,
      oneHundredKm: userPersonalBestState.oneHundredKm,
      oneHundredKmSeasonBest: userPersonalBestState.oneHundredKmSeasonBest,
    };

    updateUserPersonalBest(userPersonalBestUpdate)
      .then(() => {
        toast.success(
          "Informacje o rekordach życiowych użytkownika zostały zaktualizowane poprawnie!"
        );
        setErrors([]);
        setSaving(false);
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  };

  return (
    <div className="content">
      {loading ? (
        <Loading size="7"></Loading>
      ) : (
        <>
          <UserPersonalBestForm
            onUserPersonalBestChange={handleUserPersonalBest}
            onUserPersonalBestSave={handleUserPersonalBestSave}
            userPersonalBest={userPersonalBestState}
          />
        </>
      )}
    </div>
  );
}

userPersonalBestContainer.propTypes = {
  userPersonalBest: propTypes.object.isRequired,
  loadUserPersonalBest: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  userId: propTypes.number.isRequired,
  updateUserPersonalBest: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: state.user.selectedUser?.id || state.auth.userId,
    loading: state.apiCallsInProgress > 0,
    userPersonalBest: state.user?.userPersonalBest,
  };
}

const mapDispatchToProps = {
  loadUserPersonalBest,
  updateUserPersonalBest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userPersonalBestContainer);
