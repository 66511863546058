import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/forum";
// const baseUrl = "http://localhost:8080/trainings";

export function getThreads() {
  return fetch(baseUrl + "/thread", {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getThreadAnswers(threadId) {
  return fetch(baseUrl + `/thread/${threadId}/answers`, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCategories() {
  return fetch(baseUrl + "/categories", {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

// export function addThread(thread) {
//   return fetch(baseUrl + "/threads/", {
//     method: thread.id ? "PUT" : "POST",
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//     body: JSON.stringify(thread),
//   })
//     .then(handleResponse)
//     .catch(handleError);
// }

export function addThread(thread) {
  return fetch(baseUrl + "/thread", {
    method: thread.id ? "PUT" : "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(thread),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveForumThreadAnswer(threadAnswer) {
  return fetch(baseUrl + "/thread/answer", {
    method: threadAnswer.id ? "PUT" : "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(threadAnswer),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteThread(threadId) {
  return fetch(baseUrl + "/thread/" + threadId, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteThreadAnswer(answerId) {
  return fetch(baseUrl + "/thread/answer/" + answerId, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
