import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/strava";
const stravaUrl = "https://www.strava.com";
// const baseUrl = "http://localhost:8080/api/v1/strava";

export function getToken(urlEncodedData) {
  return fetch(stravaUrl + "/oauth/token", {
    method: "POST",
    mode: "cors",
    headers: { "Content-type": "application/x-www-form-urlencoded" },
    body: urlEncodedData.toString(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveRefreshToken(data) {
  return fetch(baseUrl + "/token", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError);
}
