import { checkIfExistingTokenIsValid } from "utils/authHelper";
import { getExistingToken } from "utils/authHelper";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const authReducer = (state = initialState.authentication, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem("token", action.credentials?.token);
      return {
        ...state,
        username: getExistingToken("token")?.sub,
        userId: getExistingToken("token")?.userId,
        firstName: getExistingToken("token")?.firstName,
        lastName: getExistingToken("token")?.lastName,
        email: getExistingToken("token")?.sub,
        isAdmin: getExistingToken("token")?.isAdmin,
        isAuthenticated: true,
        error: null,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        isAdmin: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case types.LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        isAuthenticated: false,
        isAdmin: false,
        error: null,
      };
    case types.RESET_APP:
      return checkIfExistingTokenIsValid("token")
        ? {
            ...state,
            username: getExistingToken("token")?.sub,
            userId: getExistingToken("token")?.userId,
            firstName: getExistingToken("token")?.firstName,
            lastName: getExistingToken("token")?.lastName,
            email: getExistingToken("token")?.sub,
            isAdmin: getExistingToken("token")?.isAdmin,
            isAuthenticated: true,
            error: null,
          }
        : { ...initialState };
    default:
      return state;
  }
};

export default authReducer;
