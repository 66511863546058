import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadUserProfile,
  loadUserDetails,
  updateUserProfile,
  updateUserDetails,
} from "../../redux/actions/userActions";
import propTypes from "prop-types";
import UserProfileForm from "./UserProfileForm";
import Loading from "components/Shared/Loading";
import { toast } from "react-toastify";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";
import { changeUserPasswordAction } from "redux/actions/loginActions";
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";

export function userProfileContainer({
  userDetails,
  loadUserProfile,
  loadUserDetails,
  loading,
  userId,
  userProfile,
  updateUserProfile,
  updateUserDetails,
  changeUserPasswordAction,
  isAdmin,
}) {
  const [userProfileState, setUserProfileState] = useState({});
  const [userDetailsState, setUserDetailsState] = useState({});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!IsNullOrEmptyOrUndefined(userId)) {
      loadUserProfile(userId).catch((error) => {
        console.log("Load user profile failed" + error);
      });
      loadUserDetails(userId).catch((error) => {
        console.log("Load user details failed" + error);
      });
    }
  }, [userId]);

  useEffect(() => {
    setUserProfileState(userProfile);
    setUserDetailsState(userDetails);
  }, [userProfile, userDetails]);

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === "accent") {
      setTraining((prevTraining) => ({
        ...prevTraining,
        [name]: checked,
      }));
    }

    setUserProfileState((prevUserProfile) => ({
      ...prevUserProfile,
      [name]: value,
    }));
  }

  function handleUserDetailsChange(event) {
    const { name, value } = event.target;

    setUserDetailsState((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  }

  const handleSave = (e) => {
    e.preventDefault();
    setSaving(true);
    const userProfileToUpdate = {
      id: userId,
      firstName: userProfileState.firstName,
      lastName: userProfileState.lastName,
      phoneNumber: userProfileState.phoneNumber,
      email: userProfileState.email,
      isActive: userProfileState.active,
      role: userProfileState.role,
      isArchived: userProfileState.isArchived,
    };
    updateUserProfile(userProfileToUpdate)
      .then(() => {
        toast.success("Użytkownik został zaktualizowany poprawnie!");
        setErrors([]);
        setSaving(false);
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });

    const userDetailsToUpdate = {
      userId: userId,
      age: userDetailsState.age,
      height: userDetailsState.height,
      weight: userDetailsState.weight,
      bmi: userDetailsState.bmi,
      additionalInformation: userDetailsState.additionalInformation,
    };

    const userAndPassword = {
      userId: userId,
      newPassword: userDetailsState.password,
    };

    if (userAndPassword?.newPassword) {
      changeUserPasswordAction(userAndPassword)
        .then(() => {
          setSaving(false);
          setErrors([]);
        })
        .catch((error) => {
          setSaving(false);
          setErrors({ onSave: error.message });
        });
    }

    updateUserDetails(userDetailsToUpdate)
      .then(() => {
        setErrors([]);
        setSaving(false);
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  };

  return (
    <div className="content">
      {loading ? (
        <Loading size="7"></Loading>
      ) : (
        <>
          <UserProfileForm
            onChange={handleChange}
            onUserDetailsChange={handleUserDetailsChange}
            onSave={handleSave}
            userProfile={userProfileState}
            userDetails={userDetailsState}
            isAdmin={isAdmin}
          />
        </>
      )}
    </div>
  );
}

userProfileContainer.propTypes = {
  userProfile: propTypes.object.isRequired,
  userDetails: propTypes.object.isRequired,
  loadUserProfile: propTypes.func.isRequired,
  loadUserDetails: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  userId: propTypes.number.isRequired,
  updateUserProfile: propTypes.func.isRequired,
  updateUserDetails: propTypes.func.isRequired,
  changeUserPasswordAction: propTypes.func.isRequired,
  isAdmin: propTypes.bool.isRequired,
};

function mapStateToProps(state) {
  console.log(state.user?.loggedUserInformation?.admin);
  return {
    userId: state.user.selectedUser?.id || state.auth.userId,
    loading: state.apiCallsInProgress > 0,
    userProfile: state.user?.userProfile,
    userDetails: state.user?.userDetails,
    isAdmin: state.user?.loggedUserInformation?.admin,
  };
}

const mapDispatchToProps = {
  loadUserProfile,
  loadUserDetails,
  updateUserProfile,
  updateUserDetails,
  changeUserPasswordAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userProfileContainer);
