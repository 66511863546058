import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import propTypes from "prop-types";

const UserTrainingInformationForm = ({
  onUserTrainingInfoChange,
  onUserTrainingInfoSave,
  userTrainingInformation,
}) => {
  const [localUserTrainingInfo, setLocalUserTrainingInfo] = useState({});

  useEffect(() => {
    setLocalUserTrainingInfo(userTrainingInformation);
  }, [userTrainingInformation]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Informacje treningowe</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label>Tętno maksymalne</label>
                        <Form.Control
                          placeholder="Tętno maksymalne (ud/min)"
                          type="number"
                          name="hrMax"
                          onChange={onUserTrainingInfoChange}
                          value={localUserTrainingInfo?.hrMax}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Tętno spoczynkowe</label>
                        <Form.Control
                          placeholder="Tętno spoczynkowe (ud/min)"
                          type="number"
                          name="restingHeartRate"
                          onChange={onUserTrainingInfoChange}
                          value={localUserTrainingInfo?.restingHeartRate}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Próg mleczanowy - tętno</label>
                        <Form.Control
                          placeholder="Próg mleczanowy - tętno"
                          type="number"
                          name="lactateThresholdHr"
                          onChange={onUserTrainingInfoChange}
                          value={localUserTrainingInfo?.lactateThresholdHr}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-l" md="3">
                      <Form.Group>
                        <label>Próg mleczanowy - kwas mlekowy</label>
                        <Form.Control
                          placeholder="Próg mleczanowy - tętno"
                          type="number"
                          name="lactateThreshold"
                          onChange={onUserTrainingInfoChange}
                          value={localUserTrainingInfo?.lactateThreshold}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button
                        className="btn-fill"
                        style={{ width: "25%", marginTop: "16px" }}
                        type="button"
                        variant="info"
                        onClick={onUserTrainingInfoSave}
                      >
                        Zaktualizuj informacje treningowe
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

UserTrainingInformationForm.propTypes = {
  userTrainingInformation: propTypes.object.isRequired,
  userPersonalBest: propTypes.object.isRequired,
  onUserTrainingInfoSave: propTypes.func.isRequired,
};

export default UserTrainingInformationForm;
