import { useDispatch } from "react-redux";
import { goToMainView } from "redux/actions/forumActions";
import AnswerContainer from "./AnswerContainer";
import moment from "moment";
import ActionButtons from "./ActionButtons";
import { useState } from "react";
import { deleteThreadAnswer } from "redux/actions/forumActions";

function ForumItemContent({
  thread,
  answers,
  onEditThreadAnswer,
  onAnswerThreadAnswer,
  userInfo,
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  function handleDeleteThreadAction(id) {
    dispatch(deleteThreadAnswer(id));
    setShowConfirmModal(false);
  }

  return (
    <div style={{ display: "grid" }}>
      <a
        href="#"
        onClick={() => dispatch(goToMainView())}
        className="btn btn-light btn-sm mb-3 has-icon"
        data-toggle="collapse"
        data-target=".forum-content"
        style={{ width: "96px" }}
      >
        <i className="fa fa-arrow-left mr-2"></i>Powrót
      </a>
      <div className="card mb-2">
        <div className="card-body">
          <div className="media forum-item">
            <a href="javascript:void(0)" className="card-link">
              <img
                src={require("assets/img/anFace.webp")}
                className="rounded-circle"
                width="50"
                alt="User"
              />
              <small className="d-block text-center text-muted">
                {thread.userType}
              </small>
            </a>
            <div
              className="media-body ml-3"
              style={{ marginBottom: "0px", fontSize: "14px" }}
            >
              <div className="mt-3 font-size-sm">
                <p
                  className="p-sm p-answer"
                  dangerouslySetInnerHTML={{ __html: thread.description }}
                  style={{ marginTop: "8px" }}
                />
              </div>
              <AnswerContainer
                author={thread.authorName}
                actionText={"utworzył/a w dniu:"}
                date={moment(thread.published).format("YYYY-MM-DD HH:mm")}
              />
            </div>
          </div>
        </div>
      </div>
      {answers?.map((answer) => (
        <div className="card mb-2">
          <div className="card-body">
            <div className="media forum-item">
              <a href="javascript:void(0)" className="card-link">
                <img
                  src={require("assets/img/anFace.webp")}
                  className="rounded-circle"
                  width="50"
                  alt="User"
                />
                <small className="d-block text-center text-muted">
                  {answer?.userType}
                </small>
              </a>
              <div className="media-body ml-3">
                {/* <span
                  className="text-muted"
                  style={{
                    marginBottom: "12px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {answer?.authorName}
                </span>
                <small className="text-muted ml-2">{answer?.published}</small> */}
                <div className="mt-3 font-size-sm">
                  <p
                    className="text-secondary p-answer"
                    dangerouslySetInnerHTML={{ __html: answer?.answer }}
                    style={{ marginTop: "8px" }}
                  />
                </div>
                <AnswerContainer
                  author={answer?.authorName}
                  actionText={"odpowiedział/a w dniu:"}
                  date={moment(answer?.published).format("YYYY-MM-DD HH:mm")}
                />
              </div>
              {userInfo?.userId === answer?.authorId && (
                <ActionButtons
                  key={thread.id}
                  onEdit={() => onEditThreadAnswer(answer)}
                  onDelete={() => handleDeleteThreadAction(answer?.id)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={onAnswerThreadAnswer}
        className="btn btn-success"
      >
        Odpowiedz
      </button>
    </div>
  );
}

export default ForumItemContent;
