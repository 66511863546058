import { useState } from "react";
import { useDispatch } from "react-redux";
import { goToMainView } from "redux/actions/forumActions";
import { filterForumCategories } from "redux/actions/forumActions";

function NavCategories({
  categories,
  selectedCategory,
  onHandleSelectedCategory,
}) {
  const distpatch = useDispatch();

  function handleSelectCategory(id) {
    onHandleSelectedCategory(id);
    distpatch(goToMainView());
  }
  return (
    <div className="simplebar-content" style={{ padding: "16px" }}>
      <nav className="nav-pills nav-gap-y-1 flex-column">
        <a
          href="javascript:void(0)"
          onClick={() => handleSelectCategory(0)}
          className={`nav-link nav-link-faded has-icon ${
            selectedCategory === 0 ? "active" : ""
          }`}
        >
          Wszystkie
        </a>
        {categories?.map((category) => (
          <a
            href="javascript:void(0)"
            onClick={() => handleSelectCategory(category.id)}
            className={`nav-link nav-link-faded has-icon ${
              category.id === selectedCategory ? "active" : ""
            }`}
          >
            {category.name}
          </a>
        ))}
      </nav>
    </div>
  );
}

export default NavCategories;
