import * as types from "./actionTypes";
import * as forumAPI from "../../api/forumAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadForumThreadsSuccess(threads) {
  return { type: types.LOAD_FORUM_THREADS_SUCCESS, threads };
}

export function forumLoadThreadAnswersSuccess(answers) {
  return { type: types.FORUM_LOAD_THREAD_ANSWERS_SUCCESS, answers };
}

export function loadForumCategoriesSuccess(categories) {
  return { type: types.LOAD_FORUM_CATEGORIES_SUCCESS, categories };
}

export function addForumThreadSuccess(thread) {
  return { type: types.ADD_FORUM_THREAD_SUCCESS, thread };
}

export function updateForumThreadSuccess(thread) {
  return { type: types.UPDATE_FORUM_THREAD_SUCCESS, thread };
}

export function deleteForumThread(threadId) {
  return { type: types.DELETE_FORUM_THREAD, threadId };
}

export function deleteForumThreadAnswer(answerId) {
  return { type: types.FORUM_DELETE_THREAD_ANSWER, answerId };
}

export function filterByCategory(categoryId) {
  return { type: types.FILTER_FORUM_THREADS_BY_CATEGORY, categoryId };
}

export function searchForum(text) {
  return { type: types.SEARCH_FORUM, text };
}

export function sort(sortBy) {
  return { type: types.SORT_FORUM_THREADS, sortBy };
}

export function goToMainView() {
  return { type: types.FORUM_UI_GO_TO_MAIN_VIEW };
}

export function goToThreadView() {
  return { type: types.FORUM_UI_GO_TO_THREAD_VIEW };
}

export function saveForumThreadAnswerSuccess(answer) {
  return { type: types.FORUM_SAVE_THREAD_ANSWER_SUCCESS, answer };
}

export function updateForumThreadAnswerSuccess(answer) {
  return { type: types.FORUM_UPDATE_THREAD_ANSWER_SUCCESS, answer };
}

export function loadForumThreads() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return forumAPI
      .getThreads()
      .then((threads) => {
        dispatch(loadForumThreadsSuccess(threads));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadForumThreadAnswers(threadId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return forumAPI
      .getThreadAnswers(threadId)
      .then((answers) => {
        dispatch(forumLoadThreadAnswersSuccess(answers));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function addThread(thread) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return forumAPI
      .addThread(thread)
      .then((savedThread) =>
        thread.id
          ? dispatch(updateForumThreadSuccess(thread))
          : dispatch(addForumThreadSuccess({ ...thread, id: savedThread.id }))
      )
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function deleteThread(threadId) {
  return function (dispatch) {
    dispatch(deleteForumThread(threadId));
    return forumAPI.deleteThread(threadId);
  };
}

export function deleteThreadAnswer(answerId) {
  console.log(answerId);
  return function (dispatch) {
    dispatch(deleteForumThreadAnswer(answerId));
    return forumAPI.deleteThreadAnswer(answerId);
  };
}

export function loadForumCategories() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return forumAPI
      .getCategories()
      .then((categories) => {
        dispatch(loadForumCategoriesSuccess(categories));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function filterForumCategories(categoryId) {
  return function (dispatch) {
    dispatch(filterByCategory(categoryId));
  };
}

export function search(text) {
  return function (dispatch) {
    dispatch(searchForum(text));
  };
}

export function sortForumThreads(sortBy) {
  return function (dispatch) {
    dispatch(sort(sortBy));
  };
}

export function saveForumThreadAnswer(answer) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return forumAPI
      .saveForumThreadAnswer(answer)
      .then((savedThreadAnswer) =>
        answer.id
          ? dispatch(
              updateForumThreadAnswerSuccess({
                ...answer,
                id: savedThreadAnswer.id,
              })
            )
          : dispatch(
              saveForumThreadAnswerSuccess({
                ...answer,
                id: savedThreadAnswer.id,
              })
            )
      )
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
