import React from "react";

function ColumnInformator({ icon, value, standard }) {
  const contentStyles = {
    display: "grid",
    gap: "2px",
    textAlign: "center",
  };

  const fontStyles = {
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "2px",
    marginBottom: "2px",
  };

  const valueStyles = {
    fontFamily: "system-ui",
    fontWeight: "bold",
  };

  const standardStyles = {
    fontFamily: "system-ui",
  };

  return (
    <span style={contentStyles}>
      <i className={"nc-icon " + icon} style={fontStyles}></i>
      <span style={valueStyles}>{value}</span>
      <span style={standardStyles}>{standard}</span>
    </span>
  );
}

export default ColumnInformator;
