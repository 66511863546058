import React, { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import {
  addTraining,
  deleteTraining,
  loadTrainings,
} from "../../redux/actions/trainingActions";
import propTypes from "prop-types";
import TrainingForm from "./TrainingForm/TrainingForm";
import Loading from "components/Shared/Loading";
import "./userCalendarStyles.css";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";
import { selectExercisePlan } from "redux/actions/exerciseActions";
import { loadExercisePlans } from "redux/actions/exerciseActions";
import { setSelectedPlans } from "redux/actions/exerciseActions";
import { loadExercisePlansByPlanId } from "redux/actions/exerciseActions";
import useDictionaries from "components/customHooks/useDictionaries";

const eventStyleGetter = (event) => {
  let backgroundColor = "#336699";
  switch (event.activityTypeId) {
    case 1:
      backgroundColor = "#82CAAF";
      break;
    case 2:
      backgroundColor = "#75C0E0";
      break;
    case 3:
      backgroundColor = "#ff9646";
      break;
    case 6:
      backgroundColor = "#CC3333";
      break;
    case 7:
      backgroundColor = "#FECF6A";
      break;
    case 10:
      backgroundColor = "#03002e";
      break;
    case 11:
      backgroundColor = "#957DAD";
      break;
    default:
      backgroundColor = "#336699";
      break;
  }

  return {
    style: {
      backgroundColor,
    },
  };
};

require("moment/locale/pl.js");
const localizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "white",
    },
  });

export function userCalendar({
  trainings,
  addTraining,
  deleteTraining,
  loadTrainings,
  loadExercisePlans,
  setSelectedPlans,
  loading,
  userId,
  loggedUser,
  selectedExercisesPlans,
  loadExercisePlansByPlanId,
  isAdmin,
  ...props
}) {
  const [view, setView] = useState("month");
  const [training, setTraining] = useState({ ...props.training });
  const [errors, setErrors] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedTab, setSelectedTab] = useState("general");
  const [planId, setPlanId] = useState(0);
  const [isValid, setIsValid] = useState(true);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const { activityTypes } = useDictionaries();

  const setModalIsOpenToFalse = () => {
    setErrors([]);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (trainings.length === 0 && !IsNullOrEmptyOrUndefined(userId)) {
      loadTrainings(userId).catch((error) => {
        console.log("Wystąpił problem z załadowaniem treningów: " + error);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (!IsNullOrEmptyOrUndefined(userId)) {
      loadExercisePlans(loggedUser).catch((error) => {
        console.log("Wystąpił problem z załadowaniem planów ćwiczeń: " + error);
      });
    }
  }, [userId]);

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    if (name === "accent") {
      setTraining((prevTraining) => ({
        ...prevTraining,
        [name]: checked,
      }));
    } else if (name === "kilometers") {
      setTraining((prevTraining) => ({
        ...prevTraining,
        [name]: newValue.replace(",", "."),
      }));
    } else {
      setTraining((prevTraining) => ({
        ...prevTraining,
        [name]: newValue,
      }));
    }
  }

  function handleComboboxSelect(value) {
    const { id, shortDescription } = activityTypes?.find(
      (item) => item?.title === value
    );
    setTraining((training) => ({
      ...training,
      activityTypeId: id,
      title: getTrainingTitle(id, training.title),
      description: shortDescription,
    }));
  }

  function getTrainingTitle(value, title) {
    switch (value) {
      case 10:
        return `🚷 wolne od treningu`;
      case 12:
        return `🤕 Zawodnik niezdolny do treningu`;
      default:
        return title;
    }
  }

  const handleSelectEvent = useCallback((event) => {
    setTraining(event);
    setModalIsOpenToTrue(), [];
    if (!IsNullOrEmptyOrUndefined(event.id)) {
      if (isAdmin) {
        setSelectedPlans(event.id).catch((error) => {
          console.log(
            "Wystąpił problem z załadowaniem planów ćwiczeń: " + error
          );
        });
      } else {
        loadExercisePlansByPlanId(event.id).catch((error) => {
          console.log(
            "Wystąpił problem z załadowaniem planów ćwiczeń: " + error
          );
        });
      }
    }
  });

  const handleDeleteTraining = () => {
    deleteTraining(training)
      .then((response) => toast.success("Trening został usunięty poprawnie."))
      .catch((error) => {
        toast.error("Nie udało się usunąć trening: " + error.message);
      });
    setModalIsOpenToFalse();
  };

  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    const isValidInput = /^[0-9.]*$/.test(Number(training.kilometers || 0));
    setIsValid(isValidInput);

    if (isValidInput) {
      setSaving(true);
      addTraining({
        id: training.id,
        title: training.title,
        description: training.description,
        userComment: training.userComment,
        allDay: true,
        start: moment(new Date(training.start)).format("YYYY-MM-DD"),
        end: moment(new Date(training.start)).format("YYYY-MM-DD"),
        accent: training.accent,
        userId: userId,
        addedBy: training.addedBy || loggedUser,
        exercisePlanIds: selectedExercisesPlans,
        activityTypeId:
          training.activityTypeId === null ||
          training.activityTypeId === undefined
            ? 1
            : training.activityTypeId,
        kilometers: Number(training.kilometers),
        isDone: training.isDone ? true : false,
      })
        .then(() => {
          toast.success("Trening został dodany poprawnie!");
          setModalIsOpenToFalse();
          setErrors([]);
          setSaving(false);
        })
        .catch((error) => {
          setSaving(false);
          setErrors({ onSave: error.message });
        });
    }
  };

  const handleTabChange = (e) => {
    setSelectedTab(e);
  };

  return (
    <div className="content">
      {loading ? (
        <Loading size="7"></Loading>
      ) : (
        <>
          <Calendar
            messages={{
              next: "Następny",
              previous: "Poprzedni",
              today: "Dzisiaj",
              month: "Miesiąc",
              week: "Tydzień",
              day: "Dzień",
            }}
            localizer={localizer}
            events={trainings}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectEvent}
            selectable
            showMultiDayTimes
            step={60}
            views={["month", "week", "day"]}
            view={view}
            onView={(event) => setView(event)}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper,
            }}
          />

          <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            backdropColor={"white"}
            backdropOpacity={2}
            animationIn={"slideInLeft"}
            animationOut={"slideOutRight"}
          >
            <TrainingForm
              training={training}
              loggedUser={loggedUser}
              errors={errors}
              onChange={handleChange}
              onCancel={setModalIsOpenToFalse}
              onSave={handleSave}
              onDelete={handleDeleteTraining}
              saving={saving}
              selectedTab={selectedTab}
              onTabChange={handleTabChange}
              onPlanIdChange={setPlanId}
              activityTypes={activityTypes}
              handleComboboxSelect={handleComboboxSelect}
              isValid={isValid}
              isAdmin={isAdmin}
            ></TrainingForm>
          </Modal>
        </>
      )}
    </div>
  );
}

userCalendar.propTypes = {
  training: propTypes.object.isRequired,
  addTraining: propTypes.func.isRequired,
  deleteTraining: propTypes.func.isRequired,
  trainings: propTypes.array.isRequired,
  loadTrainings: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  userId: propTypes.number,
  loggedUser: propTypes.number,
  selectedExercisesPlans: propTypes.array.isRequired,
  loadExercisePlans: propTypes.func.isRequired,
  setSelectedPlans: propTypes.func.isRequired,
  isAdmin: propTypes.bool.isRequired,
  loadExercisePlansByPlanId: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  const prepareData = (data) => {
    return data.map((obj) => {
      const newObj = { ...obj };
      newObj.start = new Date(obj.start);
      newObj.end = new Date(obj.end);
      newObj.title =
        obj.isDone && !newObj.title.startsWith("✔️")
          ? `✔️ ${obj.title}`
          : obj.title.startsWith("✔️") && !obj.isDone
          ? obj.title.substring(1)
          : obj.title;
      return newObj;
    });
  };
  return {
    loggedUser: state.auth.userId,
    isAdmin: state.auth.isAdmin,
    loading: state.apiCallsInProgress > 0,
    userId: state.user.selectedUser?.userId || state.auth.userId,
    trainings: prepareData(state.trainings),
    training: {
      id: null,
      title: "",
      description: "",
      userComment: "",
      allDay: false,
      start: null,
      end: null,
      accent: false,
      addedBy: null,
      exercisePlanId: null,
    },
    selectedExercisesPlans: state.exercisePlans.data
      ?.filter((item) => item.selected)
      .map((plan) => plan.id),
  };
}

const mapDispatchToProps = {
  addTraining,
  loadTrainings,
  deleteTraining,
  loadExercisePlans,
  setSelectedPlans,
  loadExercisePlansByPlanId,
};

export default connect(mapStateToProps, mapDispatchToProps)(userCalendar);
