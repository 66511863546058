import { Card, Button } from "react-bootstrap";
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SelectButton from "components/Shared/SelectButton.js/SelectButton";
import { selectExercisePlan } from "../../../../../redux/actions/exerciseActions";
import { connect } from "react-redux";

function Plan({
  plan,
  onShowDetails,
  selectExercisePlan,
  selectedPlans,
  isAdmin,
}) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(selectedPlans.some((sp) => sp.exercisePlanId === plan.id));
  }, [selectedPlans]);

  function handleSelectPlanButtonClick() {
    setSelected((u) => !u);
    if (!selected) {
      selectExercisePlan(plan.id);
    }
  }

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Img
        variant="top"
        src={require(plan.type === "Siłownia"
          ? "assets/img/exerciseImage.jpg"
          : "assets/img/sprawnosc.jpg")}
      />
      <Card.Body>
        <Card.Title as="h4">{plan.title}</Card.Title>
        <Card.Text>{plan.description}</Card.Text>
      </Card.Body>
      <Card.Footer className="footer-block">
        {isAdmin ? (
          <SelectButton
            isSelected={plan.selected || selected}
            onClick={handleSelectPlanButtonClick}
          >
            Wybierz
          </SelectButton>
        ) : null}
        <Button variant="info" onClick={() => onShowDetails(plan.id)}>
          Szczegóły
        </Button>
      </Card.Footer>
    </Card>
  );
}

Plan.prototype = {
  plan: propTypes.object.isRequired,
  onShowDetails: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  selectExercisePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
