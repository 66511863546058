import UserConfigurationForm from "./UserConfigurationForm";

function UserConfigurationContainer() {
  return (
    <>
      <UserConfigurationForm />
    </>
  );
}

export default UserConfigurationContainer;
