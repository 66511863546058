import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getStravaToken } from "redux/actions/stravaActions";

function StravaConnector() {
  //   const [code, setCode] = useState('');
  const location = useLocation();
  const disptach = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      const urlEncodedData = new URLSearchParams({
        client_id: "130918",
        client_secret: "011404344dbaa337ef1c4a57a45cc43330d8dfeb",
        code: code,
      });

      disptach(getStravaToken(urlEncodedData));
    }
  }, [location]);
  return (
    <div className="flex flex-row gap-10 items-center">
      <div>
        <img
          src={require("assets/img/stravaLogo.png")}
          alt="Strava"
          width="100"
        />
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="font-semibold">Aktualnie nie jesteś połączony</h2>
        <Button
          className="text-orange-500 border-orange-500 hover:text-orange-600 hover:border-orange-600"
          onClick={() =>
            (window.location.href = `https://www.strava.com/oauth/authorize?client_id=130918&response_type=code&redirect_uri=${window.location.href}&approval_prompt=force&scope=read,activity:read`)
          }
        >
          Połącz się
        </Button>
      </div>
    </div>
  );
}

export default StravaConnector;
