import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import General from "./General/General";
import Exercises from "./Exercises/Exercises";
import { isKosekTeam } from "utils/locationHelper";

const TrainingForm = ({
  training,
  onChange,
  onSave,
  onCancel,
  onDelete,
  saving,
  errors,
  loggedUser,
  onTabChange,
  selectedTab,
  activityTypes,
  handleComboboxSelect,
  isValid,
  isAdmin,
}) => {
  return (
    <Tabs
      defaultActiveKey={selectedTab}
      id="trainig-form-tabs"
      className="mb-3"
      onSelect={onTabChange}
    >
      <Tab eventKey="general" title="Ogólne">
        <General
          training={training}
          loggedUser={loggedUser}
          errors={errors}
          onChange={onChange}
          onCancel={onCancel}
          onSave={onSave}
          onDelete={onDelete}
          saving={saving}
          activityTypes={activityTypes}
          onComboboxSelect={handleComboboxSelect}
          isValid={isValid}
          isAdmin={isAdmin}
        />
      </Tab>
      {isKosekTeam() ? null : (
        <Tab eventKey="exercises" title="Ćwiczenia">
          <Exercises
            planId={training.id}
            onTabChange={onTabChange}
            selectedTab={selectedTab}
          />
        </Tab>
      )}
    </Tabs>
  );
};

export default TrainingForm;
