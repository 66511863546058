import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  userLogin
} from "../../redux/actions/loginActions";
import propTypes from "prop-types";
import LoginForm from "./LoginForm";



export function LoginPage({
  userLogin,
  ...props
}) {

  const [user, setUser] = useState({...props.user});
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }

  const handleLogin = (e) => {
    const form = e.currentTarget;
    form.checkValidity()
    e.preventDefault();

    userLogin({
      email: user.username,
      password: user.password,
    })
      .then(() => {
        setErrors([]);
      })
      .catch((error) => {
        setErrors({ onLogin: error.message });
      });
  };

  return (
    <>
          <LoginForm
            onLogin={handleLogin}
            onChange={handleChange}
            errors={errors}
          > </LoginForm>
          </>
  );
}

LoginPage.propTypes = {
  user: propTypes.object.isRequired,
  userLogin: propTypes.func.isRequired,
};

const mapDispatchToProps = {
  userLogin,
};

function mapStateToProps(state) {
    return {
      user: {
        username: "",
        password: "",
      },
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
