import { IsNullOrEmptyOrUndefined } from "./validatorHelpers";

export const getExistingToken = (name) => {
  const rawTokenFromStorage = localStorage.getItem(name);

  return IsNullOrEmptyOrUndefined(rawTokenFromStorage)
    ? null
    : JSON.parse(atob(rawTokenFromStorage.split(".")[1]));
};

export const checkIfExistingTokenIsValid = (name) => {
  const now = Date.now().valueOf() / 1000;
  const existingToken = getExistingToken(name);
  return !IsNullOrEmptyOrUndefined(existingToken) && existingToken.exp > now;
};

export const getUserName = (loggedUserInformation) => {
  return `${loggedUserInformation?.firstName} ${loggedUserInformation?.lastName}`;
};
