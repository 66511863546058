import { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { loadExercises } from "../../../../../redux/actions/exerciseActions";
import propTypes from "prop-types";
import { connect } from "react-redux";
import "../exercisesStyles.css";

function PlanDetails({
  onBackToPlans,
  exercisePlanId,
  exercises,
  loadExercises,
}) {
  useEffect(() => {
    if (exercises.length === 0 && exercisePlanId !== null)
      loadExercises(exercisePlanId).catch((error) => {
        console.log("Wystąpił problem z załadowaniem ćwiczeń: " + error);
      });
  }, [exercisePlanId]);

  function transformExercises(data) {
    let outputObject = {};

    data.forEach((item) => {
      if (!outputObject.hasOwnProperty(item.phase_order)) {
        outputObject[item.phase_order] = {
          exercises: data.filter((ex) => ex.phase_order === item.phase_order),
          phase: item.phase,
        };
      }
    });

    return outputObject;
  }

  const exercisesObject = transformExercises(exercises);

  return (
    <Card>
      <Card.Body>
        <Card.Title className="header-block" as="h4">
          Siłownia - podstawy I
        </Card.Title>
        {Object.keys(exercisesObject).map((key, index) => {
          return (
            <Card>
              <Card.Title className="set-block" as="h4">
                {exercisesObject[key]["phase"]}
              </Card.Title>
              <Card.Body>
                <Table bordered striped variant="dark">
                  <thead>
                    <tr>
                      <th>Ćwiczenie</th>
                      <th>Serie</th>
                      <th>Obciążenie</th>
                      <th>Powtórzenia</th>
                      <th>Link / Opis</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exercisesObject[key]["exercises"]?.map((exercise) => (
                      <tr>
                        <th>{exercise.title}</th>
                        <th>{exercise.series}</th>
                        <th>{exercise.weight_load}</th>
                        <th>{exercise.repetitions}</th>
                        <th>
                          {exercise.link?.includes("http") ? (
                            <a href={exercise.link}>Zobacz ćwiczenie</a>
                          ) : (
                            exercise.link
                          )}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          );
        })}
        <Card.Footer className="footer-block align-flex-to-right">
          <Button variant="secondary" onClick={onBackToPlans}>
            Powrót
          </Button>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
}

PlanDetails.propTypes = {
  exercises: propTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    exercises: state.exercisePlans?.exercises,
  };
}

const mapDispatchToProps = {
  loadExercises,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetails);
