import { useDispatch } from "react-redux";
import { goToThreadView } from "redux/actions/forumActions";
import AnswerContainer from "./AnswerContainer";
import moment from "moment";
import ActionButtons from "./ActionButtons";

function ForumItem({
  thread,
  onDeleteThread,
  onSetSelectedThread,
  onEditThread,
  userInfo,
}) {
  const dispatch = useDispatch();
  const isAccess = userInfo.userId === thread.authorId;

  function loadSelectedThread() {
    onSetSelectedThread(thread);
    dispatch(goToThreadView());
  }

  return (
    <>
      <div className="card mb-2">
        <div className="card-body p-2 p-sm-3">
          <div className="media forum-item">
            <a href="#" data-toggle="collapse" data-target=".forum-content">
              <img
                src={require("assets/img/anFace.webp")}
                className="mr-3 rounded-circle avatar-sm"
                width="50"
                alt="User"
              />
            </a>
            <div className="media-body">
              <h6>
                <a
                  href="#"
                  onClick={loadSelectedThread}
                  data-toggle="collapse"
                  data-target=".forum-content"
                  style={{ color: "#1DC7EA" }}
                >
                  {thread.title}
                </a>
              </h6>
              <p
                className="text-secondary p-answer"
                dangerouslySetInnerHTML={{ __html: thread.description }}
                style={{ marginTop: "8px" }}
              />
              <AnswerContainer
                author={thread.lastResponder || thread.authorName}
                actionText={
                  thread.lastResponder
                    ? "odpowiedział/a w dniu:"
                    : "utworzył/a w dniu:"
                }
                date={moment(thread.lastRespondDate || thread.published).format(
                  "YYYY-MM-DD HH:mm"
                )}
              />
            </div>
            <div className="text-muted small text-center small-text pl-sm">
              <span>
                <strong>Autor:</strong> {thread.authorName}
              </span>
              <div style={{ marginTop: "20px" }}>
                {/* <span className="d-none d-sm-inline-block">
                  <i className="far fa-eye"></i> {thread.watched || 0}
                </span> */}
                <span>
                  <i className="far fa-comment ml-2"></i> {thread.answered || 0}
                </span>
              </div>
              <div style={{ marginTop: "20px" }}></div>
              {isAccess && (
                <ActionButtons
                  key={thread.id}
                  onEdit={() => onEditThread(thread)}
                  onDelete={() => onDeleteThread(thread.id)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForumItem;
