import StravaConnector from "./StravaConnector";
import React from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";

function UserConfigurationForm() {
  return (
    <Container fluid className="max-w-lg p-4">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                <span className="font-semibold text-lg">
                  Synchronizacja ze Strava
                </span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-6 p-6">
                      <StravaConnector />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UserConfigurationForm;
