function ForumSorting({ onSortingChange }) {
  return (
    <select
      className="custom-select custom-select-sm w-auto mr-1"
      onChange={onSortingChange}
    >
      <option selected="" value="published">
        Ostatnio dodane
      </option>
      <option value="watched">Popularne</option>
      <option value="answered">Bez odpowiedzi</option>
    </select>
  );
}

export default ForumSorting;
