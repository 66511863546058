import * as types from "./actionTypes";
import * as authApi from "../../api/authApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loginSuccess(credentials) {
  return { type: types.LOGIN_SUCCESS, credentials };
}

export function registerUserSuccess() {
  return { type: types.REGISTER_USER_SUCCESS };
}

export function loginFailure(error) {
  return { type: types.LOGIN_FAILURE, error };
}

export function userLogout() {
  return { type: types.LOGOUT };
}

export function changeUserPasswordSuccess() {
  return { type: types.REGISTER_USER_SUCCESS };
}

export function userLogin(credentials) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .login(credentials)
      .then((credentials) => {
        dispatch(loginSuccess(credentials));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loginFailure(error));
        throw error;
      });
  };
}

export function registerUser(form) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .registerUser(form)
      .then(() => console.log("Użytkownik został zarejestrowany poprawnie"))
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loginFailure(error));
        throw error;
      });
  };
}

export function changeUserPasswordAction(userAndPassword) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .changeUserPassword(userAndPassword)
      .then(() => dispatch(changeUserPasswordSuccess()))
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loginFailure(error));
        throw error;
      });
  };
}
