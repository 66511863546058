import Plan from "./Plan/Plan";
import propTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";
import {
  loadExercisePlans,
  loadExercises,
  setSelectedPlans,
  setSelectedExercisePlanId,
} from "../../../../redux/actions/exerciseActions";
import "./exercisesStyles.css";
import PlanDetails from "./Plan/PlanDetails";

let detailsViewShow = false;

export function Exercises({
  exercisePlans,
  selectedPlans,
  loading,
  exercisePlanId,
  setSelectedExercisePlanId,
  onTabChange,
  isAdmin,
  selectedTab,
}) {
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    detailsViewShow = false;
  }, []);

  function handleShowDetails(id) {
    onTabChange("exercises");
    detailsViewShow = true;
    setSelectedExercisePlanId(id);
  }

  function handleBackToPlans() {
    detailsViewShow = false;
    setUpdated((u) => !updated);
  }

  function log() {
    return detailsViewShow && selectedTab === "exercises";
  }

  return log() ? (
    <PlanDetails
      onBackToPlans={handleBackToPlans}
      exercisePlanId={exercisePlanId}
    />
  ) : (
    <div className="exercises-block">
      {loading ? (
        <div>loading...</div>
      ) : (
        exercisePlans?.map((plan) => (
          <Plan
            plan={plan}
            key={plan.id}
            selectedPlans={selectedPlans}
            onShowDetails={handleShowDetails}
            isAdmin={isAdmin}
          />
        ))
      )}
    </div>
  );
}

Exercises.propTypes = {
  exercisePlans: propTypes.array.isRequired,
  loading: propTypes.bool.isRequired,
  userId: propTypes.number.isRequired,
  planId: propTypes.number.isRequired,
  loadExercisePlans: propTypes.func.isRequired,
  loadExercises: propTypes.func.isRequired,
  setSelectedPlans: propTypes.func.isRequired,
  selectedPlans: propTypes.array.isRequired,
  exercises: propTypes.array.isRequired,
  exercisePlanId: propTypes.number.isRequired,
  setSelectedExercisePlanId: propTypes.func.isRequired,
  isAdmin: propTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: state.user.selectedUser?.userId || state.auth.userId,
    isAdmin: state.auth.isAdmin,
    exercisePlans: state.exercisePlans?.data,
    exercisePlanId: state.exercisePlans?.selectedExercisePlanId,
    selectedPlans: JSON.parse(
      JSON.stringify(state.exercisePlans?.selectedPlans)
    ),
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadExercisePlans,
  loadExercises,
  setSelectedPlans,
  setSelectedExercisePlanId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exercises);
