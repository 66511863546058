import * as types from "../actions/actionTypes";
import initialState from "./initialState.js";

export default function coreReducer(state = initialState.core, action) {
  switch (action.type) {
    case types.SET_DATE:
      return {
        ...state,
        selectedDate: action.date,
      };
    default:
      return initialState.core;
  }
}
