import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/exercise/plans/";

export function getExercisePlans(userId) {
  return fetch(baseUrl + "user/" + userId, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSelectedExercisePlans(planId) {
  return fetch(baseUrl + planId, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getExercisePlansByPlanId(planId) {
  return fetch(baseUrl + "exercisePlans/" + planId, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getExercisesByExercisePlanId(exercisePlanId) {
  return fetch(baseUrl + "exercises/" + exercisePlanId, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}
