import React, { Component } from "react";
import { useLocation, Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import sidebarImage from "assets/img/sidebar-3.jpg";
import UserProfileContainer from "components/UserProfile/UserProfileContainer";
import UserTrainingInformationContainer from "components/UserTrainingInformation/UserTrainingInformationContainer";
import UserPersonalBestContainer from "components/UserPersonalBest/UserPersonalBestContainer";
import UserCalendar from "components/UserCalendar/UserCalendar";
import HomePage from "components/home/HomePage";
import RegisterForm from "components/LoginPage/RegisterForm";
import ForumContainer from "components/Forum/ForumContainer";
import UserConfigurationContainer from "components/UserConfiguration/UserConfigurationContainer";

function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              <Route exact path="/kreskateam" component={HomePage} />
              <Route exact path="/kosekteam" component={HomePage} />
              <Route
                exact
                path="/kreskateam/training-plan"
                component={UserCalendar}
              />
              <Route
                exact
                path="/kosekteam/training-plan"
                component={UserCalendar}
              />
              <Route path="/kreskateam/user" component={UserProfileContainer} />
              <Route path="/kosekteam/user" component={UserProfileContainer} />
              <Route
                path="/kreskateam/training-information"
                component={UserTrainingInformationContainer}
              />
              <Route
                path="/kosekteam/training-information"
                component={UserTrainingInformationContainer}
              />
              <Route
                path="/kreskateam/personal-best"
                component={UserPersonalBestContainer}
              />
              <Route
                path="/kosekteam/personal-best"
                component={UserPersonalBestContainer}
              />
              <Route path="/kreskateam/forum" component={ForumContainer} />
              <Route path="/kosekteam/forum" component={ForumContainer} />
              <Route
                path="/kreskateam/config"
                component={UserConfigurationContainer}
              />
              <Route
                path="/kosekteam/config"
                component={UserConfigurationContainer}
              />
              <Route path="/kreskateam/register" component={RegisterForm} />
              <Route path="/kosekteam/register" component={RegisterForm} />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
    </>
  );
}

export default Admin;
