import ForumForm from "./ForumForm";
import ForumItem from "./ForumItem";
import ForumItemContent from "./ForumItemContent";
import ForumPagination from "./ForumPagination";
import ForumSearch from "./ForumSearch";
import ForumSorting from "./ForumSorting";
import NavCategories from "./NavCategories";
import "./forumStyles.css";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import {
  loadForumThreads,
  loadForumCategories,
} from "redux/actions/forumActions";
import propTypes from "prop-types";
import { filterForumCategories } from "redux/actions/forumActions";
import {
  search,
  sort,
  addThread,
  deleteThread,
} from "redux/actions/forumActions";
import { loadForumThreadAnswers } from "redux/actions/forumActions";
import ForumFormAnswer from "./ForumFormAnswer";
import { goToMainView } from "redux/actions/forumActions";
import moment from "moment/moment";

function ForumContainer({
  threads,
  loadForumThreads,
  loadForumCategories,
  addThread,
  loggedUserInformation,
  filterForumCategories,
  sort,
  loadForumThreadAnswers,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [answerModalIsOpen, setAnswerModalIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [form, setForm] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories === null || categories?.length === 0) {
      loadForumCategories().catch((error) =>
        console.log("Problem podczas ładowania kategorii forum.")
      );
    }

    if (threads === null || threads?.length === 0) {
      loadForumThreads().catch((error) =>
        console.log("Problem podczas ładowania wątków forum.")
      );
    }
  }, []);

  useEffect(() => {
    if (selectedThread) {
      loadForumThreadAnswers(selectedThread.id).catch((error) =>
        console.log("Problem podczas ładowania odpowiedzi.")
      );
    }
  }, [selectedThread]);

  const categories = useSelector((store) => store.forum.categories);
  const mainViewVisible = useSelector((store) => store.forum.ui.mainView);
  const threadAnswers = useSelector((store) => store.forum.threadAnswers);
  const userInfo = useSelector((store) => store.auth);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setForm(null);
    setModalIsOpen(false);
  };

  const setAnswerModalIsOpenToTrue = () => {
    setAnswerModalIsOpen(true);
  };

  const setAnswerModalIsOpenToFalse = () => {
    setForm(null);
    setAnswerModalIsOpen(false);
  };

  function handleAddForumThread(form) {
    console.log(loggedUserInformation);
    form.authorName = `${loggedUserInformation?.firstName} ${loggedUserInformation?.lastName}`;
    form.published = new Date();
    form.authorId = loggedUserInformation?.userId;
    // form.categoryId = selectedCategoryId;
    addThread(form);
  }

  function handleSelectedCategory(categoryId) {
    console.log(categoryId);
    setSelectedCategory(categoryId);
    filterForumCategories(categoryId);
  }

  function handleForumSearch(value) {
    dispatch(search(value));
  }

  function handleSortingChange(e) {
    sort(e.target.value);
  }

  function handleEditThread(thread) {
    console.log(thread);
    setForm(thread);
    setModalIsOpenToTrue();
  }

  function handleDeleteThread(id) {
    dispatch(deleteThread(id));
    setShowConfirmModal(false);
  }

  function handleEditThreadAnswer(answer) {
    setForm(answer);
    setAnswerModalIsOpenToTrue();
  }

  function handleAnswerThreadAnswer() {
    setAnswerModalIsOpenToTrue();
  }

  function initNewThread() {
    dispatch(goToMainView());
    setModalIsOpenToTrue();
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css"
        integrity="sha256-46r060N2LrChLLb5zowXQ72/iKKNiw/lAmygmHExk/o="
        crossorigin="anonymous"
      />
      <div className="container">
        <div className="main-body p-0">
          <div className="inner-wrapper">
            <div className="inner-sidebar">
              <div className="inner-sidebar-header justify-content-center">
                <button
                  className="btn btn-primary has-icon btn-block"
                  type="button"
                  onClick={initNewThread}
                >
                  Nowy wątek
                </button>
              </div>

              <div className="inner-sidebar-body p-0">
                <div className="p-3 h-100" data-simplebar="init">
                  <div
                    className="simplebar-wrapper"
                    style={{ margin: "-16px" }}
                  >
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                      <div
                        className="simplebar-offset"
                        style={{ right: "0px", bottom: "0px" }}
                      >
                        <div
                          className="simplebar-content-wrapper"
                          style={{ height: "100%" }}
                        >
                          <NavCategories
                            categories={categories}
                            onHandleSelectedCategory={handleSelectedCategory}
                            selectedCategory={selectedCategory}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="simplebar-placeholder"
                      style={{ width: "234px", height: "292px" }}
                    ></div>
                  </div>
                  <div
                    className="simplebar-track simplebar-horizontal"
                    style={{ visibility: "hidden" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{ width: "0px", display: "none" }}
                    ></div>
                  </div>
                  <div
                    className="simplebar-track simplebar-vertical"
                    style={{ visibility: "visible" }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{
                        height: "151px",
                        display: "block",
                        transform: "translate3d(0px, 0px, 0px)",
                      }}
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="inner-main">
              <div className="inner-main-header">
                {/* <a
                  className="nav-link nav-icon rounded-circle nav-link-faded mr-3 d-md-none"
                  href="#"
                  data-toggle="inner-sidebar"
                >
                  <i className="material-icons">arrow_forward_ios</i>
                </a> */}

                {mainViewVisible ? (
                  <>
                    <ForumSorting onSortingChange={handleSortingChange} />
                    <ForumSearch onHandleForumSearch={handleForumSearch} />
                  </>
                ) : (
                  <div
                    style={{
                      display: "block",
                      width: "100%",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    <h5
                      className="mt-1"
                      style={{
                        fontWeight: "700",
                        fontSize: "17px",
                        color: "#1DC7EA",
                      }}
                    >
                      {selectedThread.title}
                    </h5>
                  </div>
                )}
              </div>

              <div
                className="inner-main-body p-2 p-sm-3 collapse forum-content show"
                style={{ overflowX: "auto" }}
              >
                {mainViewVisible ? (
                  threads?.map((thread) => (
                    <ForumItem
                      key={thread.id}
                      thread={thread}
                      onEditThread={handleEditThread}
                      onDeleteThread={() => handleDeleteThread(thread.id)}
                      showConfirmModal={showConfirmModal}
                      onSetShowConfirmModal={setShowConfirmModal}
                      onSetSelectedThread={setSelectedThread}
                      userInfo={userInfo}
                    >
                      {/* <ActionButtons
                        key={thread.id}
                        onEditThread={() => handleEditThread(thread)}
                        onSetShowConfirmModal={setShowConfirmModal}
                      /> */}
                    </ForumItem>
                  ))
                ) : (
                  <ForumItemContent
                    key={threadAnswers.id}
                    thread={selectedThread}
                    answers={threadAnswers}
                    onSetAnswerModalIsOpen={setAnswerModalIsOpen}
                    onEditThreadAnswer={handleEditThreadAnswer}
                    onAnswerThreadAnswer={handleAnswerThreadAnswer}
                    userInfo={userInfo}
                  />
                )}
              </div>

              {/* <ForumPagination /> */}
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            backdropColor={"white"}
            backdropOpacity={2}
            animationIn={"slideInLeft"}
            animationOut={"slideOutRight"}
          >
            <ForumForm
              onCancel={setModalIsOpenToFalse}
              onAddForumThread={handleAddForumThread}
              categories={categories}
              form={form}
              onSetForm={setForm}
              onSetSelectedCategoryId={setSelectedCategoryId}
            />
          </Modal>

          <Modal
            isOpen={answerModalIsOpen}
            ariaHideApp={false}
            backdropColor={"white"}
            backdropOpacity={2}
            animationIn={"slideInLeft"}
            animationOut={"slideOutRight"}
          >
            <ForumFormAnswer
              onCancel={setAnswerModalIsOpenToFalse}
              form={form}
              onSetForm={setForm}
              onEditAnswer={handleEditThreadAnswer}
              userInfo={userInfo}
              threadId={selectedThread?.id}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}

ForumContainer.propTypes = {
  loadForumThreads: propTypes.func.isRequired,
  loadForumCategories: propTypes.func.isRequired,
  addThread: propTypes.func.isRequired,
  filterForumCategories: propTypes.func.isRequired,
  loggedUserInformation: propTypes.object.isRequired,
  sort: propTypes.func.isRequired,
  loadForumThreadAnswers: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    uiState: state.forum.ui,
    threads: state.forum.threads,
    loggedUserInformation: state.user.loggedUserInformation,
  };
}

const mapDispatchToProps = {
  loadForumThreads,
  loadForumCategories,
  addThread,
  filterForumCategories,
  sort,
  loadForumThreadAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumContainer);
