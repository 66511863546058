import { BsCalendar3 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./dateNavigatorStyles.css";

function DateNavigator({ currentDate, setCurrentDate }) {
  //   const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

  function getCurrentDay(day) {
    const weekDays = [
      "niedziela",
      "poniedziałek",
      "wtorek",
      "środa",
      "czwartek",
      "piątek",
      "sobota",
    ];

    const dayNumber = new Date(day).getDay();
    return weekDays[dayNumber];
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <button
        type="button"
        className="btn btn-primary"
        onClick={() =>
          setCurrentDate(
            moment(currentDate).subtract(1, "day").format("YYYY-MM-DD")
          )
        }
      >
        ◀
      </button>
      <div style={{ margin: "0 14px 0 14px", display: "flex" }}>
        <BsCalendar3
          style={{
            width: "18px",
            height: "18px",
            fill: "#3472F7",
            marginRight: "4px",
            position: "relative",
            top: "3px",
          }}
        />
        <DatePicker
          name="selectedDate"
          wrapperClassName="datePicker"
          value={currentDate}
          onChange={(e) => setCurrentDate(moment(e).format("YYYY-MM-DD"))}
        ></DatePicker>
        <span className="week-day">({getCurrentDay(currentDate)})</span>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        onClick={() =>
          setCurrentDate(moment(currentDate).add(1, "day").format("YYYY-MM-DD"))
        }
      >
        ▶
      </button>
    </div>
  );
}

export default DateNavigator;
