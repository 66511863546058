import React, { useEffect, useState } from "react";
import RichCard from "./RichCard/RichCard";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { loadTrainings } from "redux/actions/trainingActions";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const weekLabels = ["pn", "wt", "śr", "czw", "pt", "sob", "nd"];

function HomePage({ trainings, userId, loadTrainings, selectedDate }) {
  const [period, setPeriod] = useState("tydzień");
  const [chartLabels, setChartLabels] = useState(weekLabels);
  const [sum, setSum] = useState(0);
  const [chartData, setChartData] = useState(() => ({
    labels: chartLabels,
    datasets: [
      {
        label: "Liczba kilometrów",
        data: getWeekData().output,
        borderColor: "#3472F7",
        backgroundColor: "#3472F7",
      },
    ],
  }));

  useEffect(() => {
    if (!IsNullOrEmptyOrUndefined(userId)) {
      loadTrainings(userId).catch((error) => {
        console.log("Wystąpił problem z załadowaniem treningów: " + error);
      });
    }
  }, [userId]);

  useEffect(() => {
    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: "Liczba kilometrów",
          data: getWeekData().output,
          borderColor: "#3472F7",
          backgroundColor: "#3472F7",
        },
      ],
    });
    setSum(getWeekData()?.accumulator);
  }, [trainings]);

  const headerStyles = {
    margin: "0px 0px 10px",
    fontFamily: "system-ui",
    fontWeight: "600",
  };

  const cardBodyStyles = {
    display: "flex",
    justifyContent: "space-around",
  };

  const contentStyles = {
    display: "flex",
    justifyContent: "space-around",
    padding: "4px 16px 4px 16px",
    flexWrap: "wrap",
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Liczba kilometrów za bieżący ${period}: ${sum.toFixed(1)}`,
      },
    },
  };

  const yearsLabels = getYearsLabels();
  const monthsLabels = getMonthData();

  function getYearsLabels() {
    const shortMonthNames = [];
    for (let i = 0; i < 12; i++) {
      const shortName = moment().month(i).format("MMM");
      shortMonthNames.push(shortName);
    }
    return shortMonthNames;
  }

  function getMonthData() {
    const currentDate = moment();

    // Pobieramy numer aktualnego miesiąca
    const currentMonth = currentDate.month() + 1;

    const filteredData = trainings.filter(
      (entry) =>
        moment(entry.start).month() + 1 === currentMonth &&
        entry.unit === "kilometry"
    );

    // Tworzymy tablicę tygodni dla bieżącego miesiąca
    const weeksInMonth = [];
    const startOfMonth = moment(currentDate).startOf("month");
    const endOfMonth = moment(currentDate).endOf("month");

    // Iterujemy przez tygodnie w bieżącym miesiącu
    let startOfWeek = startOfMonth.clone().startOf("isoWeek");
    let endOfWeek = startOfWeek.clone().endOf("isoWeek");

    while (startOfWeek.isSameOrBefore(endOfMonth)) {
      weeksInMonth.push({
        start: startOfWeek.clone(),
        end: endOfWeek.clone(),
      });

      startOfWeek.add(1, "week");
      endOfWeek.add(1, "week");
    }

    // Uzyskaj dzień miesiąca bieżącej daty
    const dayOfMonth = currentDate.date();

    // Uzyskaj dzień tygodnia, który jest pierwszym dniem miesiąca
    const firstDayOfMonth = moment(currentDate).startOf("month").isoWeekday();

    // Oblicz, ile pełnych tygodni minęło od początku miesiąca
    let fullWeeks = Math.floor((dayOfMonth - 1 + firstDayOfMonth) / 7);

    // Popraw numer tygodnia, jeśli pierwszy dzień miesiąca nie jest niedzielą
    if (firstDayOfMonth !== 7) {
      fullWeeks++;
    }

    // Inicjalizujemy obiekt do przechowywania sumy kilometrów dla każdego tygodnia
    const weeklySum = {};

    // Iterujemy przez tygodnie i sumujemy przebyte kilometry
    weeksInMonth.forEach((week, index) => {
      if (index + 1 <= fullWeeks) {
        if (index + 1 <= currentDate.week()) weeklySum[index + 1] = 0;

        // Iterujemy przez dane i sumujemy przebyte kilometry dla danego tygodnia
        filteredData.forEach((entry) => {
          const entryDate = moment(entry.start);

          if (entryDate.isBetween(week.start, week.end, null, "[]")) {
            weeklySum[index + 1] += entry.kilometers;
          }
        });
        if (index > 0) {
          weeklySum[index + 1] += weeklySum[index];
        }
      }
    });
    const labels = Object.keys(weeksInMonth).map(
      (week) => `Tydzień ${Number(week) + 1}`
    );
    const values = Object.values(weeklySum);
    let sum = 0;
    filteredData.reduce((prev, acc) => {
      sum += acc.kilometers;
    }, 0);
    return { labels, values, sum };
  }

  function getWeekData() {
    const startOfWeek = moment().startOf("week");
    const currentDay = moment();
    const dayOfWeek = currentDay.weekday() + 1;

    let weekObject = {};

    for (let index = 0; index < dayOfWeek; index++) {
      let dateKey = startOfWeek.format("YYYY-MM-DD");
      weekObject[dateKey] = 0;
      startOfWeek.add(1, "day");
    }
    const dataToProcess = [];

    Object.keys(weekObject).map((key) => {
      dataToProcess.push(
        trainings.filter(
          (item) => item?.start === key && item?.unit === "kilometry"
        )[0]?.kilometers || 0
      );
    });

    const output = [];
    let accumulator = 0;

    dataToProcess?.reduce((prev, acc) => {
      accumulator += acc;
      output.push(accumulator);
      return acc;
    }, 0);

    return { output, accumulator };
  }

  function handleChart(period) {
    if (period === "month") {
      setPeriod("miesiąc");
      setSum(getMonthData()?.sum);
      setChartData({
        labels: getMonthData().labels,
        datasets: [
          {
            label: "Liczba kilometrów",
            data: getMonthData().values,
            borderColor: "#FF4A55",
            backgroundColor: "#FF4A55",
          },
        ],
      });
    } else {
      setPeriod("tydzień");
      setSum(getWeekData()?.accumulator);
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Liczba kilometrów",
            data: getWeekData().output,
            borderColor: "#3472F7",
            backgroundColor: "#3472F7",
          },
        ],
      });
    }
  }

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Liczba kilometrów",
        data: getWeekData().output,
        borderColor: "#3472F7",
        backgroundColor: "#3472F7",
      },
    ],
  };

  return (
    <>
      <div style={contentStyles}>
        <div>
          <h4 style={headerStyles}>Twój plan dnia</h4>
          <Card bg="light">
            <Card.Body style={cardBodyStyles}>
              <RichCard data={trainings} date={selectedDate} />
            </Card.Body>
          </Card>
        </div>
        <div style={{ width: "700px", height: "490px" }}>
          <Line options={options} data={chartData} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Button
              className="btn-fill"
              style={{ width: "100%", marginTop: "16px" }}
              type="button"
              variant="primary"
              onClick={() => handleChart("week")}
            >
              Tydzień
            </Button>
            <Button
              className="btn-fill"
              style={{ width: "100%", marginTop: "16px" }}
              type="button"
              variant="danger"
              onClick={() => handleChart("month")}
            >
              Miesiąc
            </Button>
            {/* <Button
              className="btn-fill"
              style={{ width: "100%", marginTop: "16px" }}
              type="button"
              variant="success"
            >
              Rok
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    trainings: state.trainings,
    userId: state.user.selectedUser?.userId || state.auth.userId,
    selectedDate: state.core.selectedDate,
  };
}

const mapDispatchToProps = { loadTrainings };

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
