import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import propTypes from "prop-types";
import { registerUser } from "redux/actions/loginActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isKosekTeam } from "../../utils/locationHelper";

function RegisterForm({ registerUser }) {
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(true);
  const [isRegistred, setIsRegistred] = useState(false);
  const history = useHistory();

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formTarget = event.currentTarget;

    const { password, confirmPassword } = form;

    if (password !== confirmPassword) {
      setPasswordConfirmed(false);
      setValidated(false);
    }

    if (formTarget.checkValidity() === false || password !== confirmPassword) {
      event.stopPropagation();
      setValidated(false);
    }

    setValidated(true);

    if (formTarget.checkValidity() && password === confirmPassword) {
      form.coachId = isKosekTeam() ? 27 : 7;

      registerUser(form).then(() => setIsRegistred(true));
    }
  }
  return (
    <Container fluid style={{ marginTop: "5%", width: "60%" }}>
      <Card>
        <Card.Header>
          <Card.Title as="h3" className="text-center">
            {isRegistred ? "Dziękujemy za rejestrację!" : "Utwórz konto"}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {!isRegistred ? (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Adres e-mail</Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Wpisz adres e-mail"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Wprowadź adres e-mail
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Imię</Form.Label>
                <Form.Control
                  placeholder="Wpisz imię"
                  type="text"
                  required
                  name="firstName"
                  id="firstName"
                  onChange={handleChange}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Wprowadź imię
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nazwisko</Form.Label>
                <Form.Control
                  placeholder="Wpisz nazwisko"
                  type="text"
                  name="lastName"
                  required
                  id="lastName"
                  onChange={handleChange}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Wprowadź nazwisko
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                  type="password"
                  required
                  placeholder="Wpisz hasło"
                  id="password"
                  name="password"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Wprowadź hasło
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Powtórz hasło</Form.Label>
                <Form.Control
                  type="password"
                  required
                  placeholder="Powtórz hasło"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={handleChange}
                  isInvalid={!passwordConfirmed}
                  style={{
                    borderColor: passwordConfirmed ? "" : "red",

                    backgroundImage: passwordConfirmed ? "" : "none",
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Hasła muszą pasować
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group style={{ display: "flex", gap: "10px" }}>
                <Button
                  block
                  variant="danger"
                  onClick={() =>
                    history.push(
                      isKosekTeam()
                        ? (window.location.href = "/kosekteam")
                        : (window.location.href = "/kreskateam")
                    )
                  }
                  type="button"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  Anuluj
                </Button>
                <Button
                  block
                  variant="primary"
                  type="submit"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  Zarejestruj
                </Button>
              </Form.Group>
            </Form>
          ) : (
            <div style={{ textAlign: "center" }}>
              <p>
                Twój profil został pomyślnie utworzony. Teraz możesz przejść do
                strony logowania i zalogować się na swoje konto.
              </p>
              <p>
                <a href={isKosekTeam() ? "/kosekteam" : "/kreskateam"}>
                  Przejdź do strony logowania
                </a>
              </p>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = {
  registerUser,
};

export default connect(null, mapDispatchToProps)(RegisterForm);
