import { combineReducers } from "redux";
import trainings from "./trainingReducer";
import auth from "./authReducer";
import user from "./userReducer";
import exercisePlans from "./exerciseReducer";
import apiCallsInProgress from "./apiStatusReducer";
import core from "./coreReducer";
import forum from "./forumReducer";

const rootReducer = combineReducers({
  trainings,
  auth,
  apiCallsInProgress,
  user,
  exercisePlans,
  core,
  forum,
});

export default rootReducer;
