import React, { useState } from "react";
import propTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col, Container, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./generalCustomStyles.css";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";
import "react-datepicker/dist/react-datepicker.css";
import ComboBox from "react-responsive-combo-box";
import "react-responsive-combo-box/dist/index.css";

const today = new Date();

function General({
  training,
  onChange,
  onComboboxSelect,
  onSave,
  onCancel,
  onDelete,
  saving,
  errors,
  loggedUser,
  activityTypes,
  isValid,
  isAdmin,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [showDropdown, setShowDropdown] = useState(true);

  // const defaultActivityTypeValue = activityTypes?.find(
  //   (item) => item.id === training.activityTypeId
  // )?.title;
  const defaultActivityTypeValue = "Wybierz typ treningu";

  const disableField = () => {
    return loggedUser !== training.addedBy && !isAdmin && training?.id > 0;
  };

  const deleteButtonClass = `delete-button ${disableField() ? "hidden" : ""}`;

  const valid = (e) => {
    const isValidInput = /^[0-9.]*$/.test(e.target.value);
    setIsValid(isValidInput);
  };

  return (
    <Container fluid>
      <Form onSubmit={onSave}>
        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="activityType">
              <Form.Label>Typ Treningu</Form.Label>
              <div>
                <select
                  name="trainingType"
                  id="trainingType"
                  className="custom-dropdown"
                  disabled={disableField()}
                  onChange={(e) => onComboboxSelect(e.target.value)}
                >
                  {activityTypes?.map((option, index) => (
                    <option
                      value={option?.title}
                      key={index}
                      selected={option?.id === training?.activityTypeId}
                    >
                      {option?.title}
                    </option>
                  ))}
                </select>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1 input-field" md="9">
            <Form.Group className="mb-3" controlId="training">
              <Form.Label>Trening</Form.Label>
              <Alert show={"onSave" in errors} variant="danger">
                {errors.onSave}
              </Alert>
              <Form.Control
                type="text"
                required
                placeholder="Wprowadź trening"
                name="title"
                onChange={onChange}
                disabled={disableField()}
                value={training.title}
              />
              <Form.Control.Feedback type="invalid">
                To pole jest wymagane!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="pr-1" md="3">
            <Form.Group controlId="validationFormik03">
              <Form.Label style={{ marginRight: "9px" }}>
                Data treningu
              </Form.Label>

              <DatePicker
                selected={startDate}
                value={moment(training.start).format("YYYY-MM-DD")}
                name="start"
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "start",
                      value: e,
                    },
                  }),
                    setStartDate(e);
                }}
                className="form-control"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="trainingDescription">
              <Form.Label>Opis Treningu</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Wprowadź opis treningu"
                name="description"
                onChange={onChange}
                disabled={disableField()}
                defaultValue={"Bieg ciągły w pierwszy zakresie"}
                value={training.description}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="userComment">
              <Form.Label>Komentarz zawodnika</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Wprowadź swój komentarz"
                name="userComment"
                onChange={onChange}
                value={training.userComment}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="6">
            <Form.Group controlId="accent">
              <Form.Check className="mb-1 pl-0">
                <Form.Check.Label>
                  <Form.Check.Input
                    checked={training.isDone}
                    value={training.isDone}
                    name="isDone"
                    onChange={onChange}
                    type="checkbox"
                  ></Form.Check.Input>
                  <span className="form-check-sign"></span>
                  Zrealizowano?
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Col>

          <Col className="pr-1" md="6">
            <Form.Group controlId="training">
              <Form.Label>Liczba kilometrów</Form.Label>
              <Form.Control
                type="text"
                placeholder="na przykład: 10.2"
                name="kilometers"
                isInvalid={!isValid}
                onChange={onChange}
                value={training?.kilometers}
              />
              <Form.Control.Feedback type="invalid">
                Akceptowany jest tylko format liczbowy (np. 10.2).
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3 buttons mgt-12" controlId="actions">
              <Button
                variant="danger"
                className={deleteButtonClass}
                type="button"
                onClick={onDelete}
                visible
                disabled={disableField()}
              >
                Usuń
              </Button>
              <Button variant="primary" type="button" onClick={onCancel}>
                Anuluj
              </Button>
              <Button variant="primary" type="submit" disabled={saving}>
                {saving ? "Zapisuje..." : "Zapisz"}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

General.propTypes = {
  training: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
  saving: propTypes.bool,
  loggedUser: propTypes.bool.isRequired,
  isAdmin: propTypes.bool.isRequired,
};

export default General;
