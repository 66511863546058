function AnswerContainer({ author, actionText, date }) {
  return (
    <p
      className="text-muted"
      style={{
        marginBottom: "12px",
        fontSize: "13px",
        fontStyle: "italic",
        overflowWrap: "anywhere",
      }}
    >
      <a
        style={{
          fontWeight: "600",
          fontSize: "13px",
          overflowWrap: "anywhere",
        }}
      >
        {author}
      </a>{" "}
      {actionText}{" "}
      <span
        className="text-secondary font-weight-bold"
        style={{ overflowWrap: "anywhere" }}
      >
        {date}
      </span>
    </p>
  );
}

export default AnswerContainer;
