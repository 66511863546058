import * as types from "./actionTypes";
import * as stravaAPI from "../../api/stravaAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

function getStravaTokenSuccess() {
  return { type: types.GET_STRAVA_TOKEN_SUCCESS };
}

function saveStravaTokenSuccess() {
  return { type: types.SAVE_STRAVA_TOKEN_SUCCESS };
}

function saveStravaRefreshTokenToDb(data) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .saveRefreshToken(data)
      .then(() =>
        dispatch(saveStravaTokenSuccess()).catch((error) =>
          dispatch(apiCallError(error))
        )
      );
  };
}

export function getStravaToken(formData) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .getToken(formData)
      .then((response) => {
        dispatch(getStravaTokenSuccess());
        dispatch(saveStravaRefreshTokenToDb(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}
