import * as types from "./actionTypes";
import * as trainingApi from "../../api/trainingsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadTrainingsSuccess(trainings) {
  return { type: types.LOAD_TRAININGS_SUCCESS, trainings };
}

export function updateTrainingSuccess(training) {
  return { type: types.UPDATE_TRAINING_SUCCESS, training };
}

export function addTrainingSuccess(training) {
  return { type: types.ADD_TRAINING_SUCCESS, training };
}

export function deleteTrainingOptimistic(training) {
  return { type: types.DELETE_TRAINING_OPTIMISTIC, training };
}

export function addTraining(training) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return trainingApi
      .addTraining(training)
      .then((savedTraining) => {
        training.id
          ? dispatch(updateTrainingSuccess(savedTraining))
          : dispatch(addTrainingSuccess(savedTraining));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadTrainings(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return trainingApi
      .getTrainings(userId)
      .then((trainings) => {
        dispatch(loadTrainingsSuccess(trainings));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function deleteTraining(training) {
  return function (dispatch) {
    dispatch(deleteTrainingOptimistic(training));
    return trainingApi.deleteTraining(training.id);
  };
}
