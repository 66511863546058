import { getActivityTypes } from "api/dictonariesAPI";
import { useEffect, useState } from "react";

export default function useDictionaries() {
  const [activityTypes, setActivityTypes] = useState([]);
  useEffect(function () {
    function getDictionaries() {
      getActivityTypes().then((data) => setActivityTypes(data));
    }

    getDictionaries();
  }, []);

  return { activityTypes };
}
