import { handleResponse, handleError } from "./apiUtils";
const baseUrl =
  process.env.REACT_APP_API_URL + "/api/v1/dictionaries/activityTypes";

export function getActivityTypes() {
  return fetch(baseUrl, {
    method: "GET",
    mode: "cors",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}
