import React, { useEffect, useState } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import propTypes from "prop-types";
import ComputePace from "./ComputePace";

const UserPersonalBestForm = ({
  onUserPersonalBestChange,
  onUserPersonalBestSave,
  userPersonalBest,
}) => {
  const [localUserPersonalBest, setUserPersonalBest] = useState({});

  useEffect(() => {
    setUserPersonalBest(userPersonalBest);
  }, [userPersonalBest]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Rekordy życiowe</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>5 km</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="5 km"
                            type="text"
                            name="fiveKm"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.fiveKm}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "fiveKm",
                                    value: localUserPersonalBest?.fiveKm,
                                  },
                                },
                                5
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <label>5 km - Season Best</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="5 km - Season Best"
                            type="text"
                            name="fiveKmSeasonBest"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.fiveKmSeasonBest}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "fiveKmSeasonBest",
                                    value:
                                      localUserPersonalBest?.fiveKmSeasonBest,
                                  },
                                },
                                5
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>10 km</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="10 km"
                            type="text"
                            name="tenKm"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.tenKm}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "tenKm",
                                    value: localUserPersonalBest?.tenKm,
                                  },
                                },
                                10
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>10 km - Season Best</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="10 km - Season Best"
                            type="text"
                            name="tenKmSeasonBest"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.tenKmSeasonBest}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "tenKmSeasonBest",
                                    value:
                                      localUserPersonalBest?.tenKmSeasonBest,
                                  },
                                },
                                10
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>Półmaraton</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="Półmaraton"
                            type="text"
                            name="halfMarathon"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.halfMarathon}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "halfMarathon",
                                    value: localUserPersonalBest?.halfMarathon,
                                  },
                                },
                                21.1
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>Półmaraton - Season Best</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="Półmaraton - Season Best"
                            type="text"
                            name="halfMarathonSeasonBest"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={
                              localUserPersonalBest?.halfMarathonSeasonBest
                            }
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "halfMarathonSeasonBest",
                                    value:
                                      localUserPersonalBest?.halfMarathonSeasonBest,
                                  },
                                },
                                21.1
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>Maraton</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="Maraton"
                            type="text"
                            name="marathon"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.marathon}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "marathon",
                                    value: localUserPersonalBest?.marathon,
                                  },
                                },
                                42.2
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <label>Maraton - Season Best</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="Maraton - Season Best"
                            type="text"
                            name="marathonSeasonBest"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.marathonSeasonBest}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "marathonSeasonBest",
                                    value:
                                      localUserPersonalBest?.marathonSeasonBest,
                                  },
                                },
                                42.2
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>50 km</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="50 km"
                            type="text"
                            name="fiftyKm"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.fiftyKm}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "fiftyKm",
                                    value: localUserPersonalBest?.fiftyKm,
                                  },
                                },
                                50
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>50 km - Season Best</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="50 km - Season Best"
                            type="text"
                            name="fiftyKmSeasonBest"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.fiftyKmSeasonBest}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "fiftyKmSeasonBest",
                                    value:
                                      localUserPersonalBest?.fiftyKmSeasonBest,
                                  },
                                },
                                50
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>100 km</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="100 km"
                            type="text"
                            name="oneHundredKm"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={localUserPersonalBest?.oneHundredKm}
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "oneHundredKm",
                                    value: localUserPersonalBest?.oneHundredKm,
                                  },
                                },
                                100
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <label>100 km - Season Best</label>
                        <div style={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "90%" }}
                            placeholder="100 km - Season Best"
                            type="text"
                            name="oneHundredKmSeasonBest"
                            onChange={(e) => onUserPersonalBestChange(e)}
                            value={
                              localUserPersonalBest?.oneHundredKmSeasonBest
                            }
                          ></Form.Control>
                          <ComputePace
                            onUserPersonalBestChange={() =>
                              onUserPersonalBestChange(
                                {
                                  target: {
                                    name: "oneHundredKmSeasonBest",
                                    value:
                                      localUserPersonalBest?.oneHundredKmSeasonBest,
                                  },
                                },
                                100
                              )
                            }
                          ></ComputePace>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>24 h</label>
                        <Form.Control
                          style={{ width: "90%" }}
                          placeholder="24 h"
                          type="text"
                          name="twentyFourKm"
                          onChange={(e) =>
                            onUserPersonalBestChange(e, 24, true)
                          }
                          value={localUserPersonalBest?.twentyFourKm}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <label>24 h - Season Best</label>
                        <Form.Control
                          style={{ width: "90%" }}
                          placeholder="24 h - Season Best"
                          type="text"
                          name="twentyFourKmSeasonBest"
                          onChange={(e) =>
                            onUserPersonalBestChange(e, 24, true)
                          }
                          value={localUserPersonalBest?.twentyFourKmSeasonBest}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>48 h</label>
                        <Form.Control
                          style={{ width: "90%" }}
                          placeholder="48 h"
                          type="text"
                          name="fourtyEightKm"
                          onChange={(e) =>
                            onUserPersonalBestChange(e, 48, true)
                          }
                          value={localUserPersonalBest?.fourtyEightKm}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <label>48 h - Season Best</label>
                        <Form.Control
                          style={{ width: "90%" }}
                          placeholder="48 h - Season Best"
                          type="text"
                          name="fourtyEightKmSeasonBest"
                          onChange={(e) =>
                            onUserPersonalBestChange(e, 48, true)
                          }
                          value={localUserPersonalBest?.fourtyEightKmSeasonBest}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button
                        className="btn-fill"
                        style={{ width: "30%", marginTop: "16px" }}
                        type="button"
                        variant="info"
                        onClick={onUserPersonalBestSave}
                      >
                        Zaktualizuj rekordy życiowe
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

UserPersonalBestForm.propTypes = {
  userProfile: propTypes.object.isRequired,
  userDetails: propTypes.object.isRequired,
  userTrainingInformation: propTypes.object.isRequired,
  userPersonalBest: propTypes.object.isRequired,
  onUserPersonalBestSave: propTypes.func.isRequired,
};

export default UserPersonalBestForm;
