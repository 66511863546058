import ConfirmationModal from "components/Shared/ConfirmationModal/ConfirmationModal";
import { useState } from "react";

function ActionButtons({ onEdit, onDelete }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  function handleDelete() {
    onDelete();
    setShowConfirmModal(false);
  }
  return (
    <div style={{ marginTop: "20px", alignSelf: "end" }}>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        style={{ marginRight: "8px" }}
        onClick={onEdit}
      >
        Edytuj
      </button>
      <button
        type="button"
        class="btn btn-danger btn-sm"
        onClick={() => setShowConfirmModal(true)}
      >
        Usuń
      </button>
      <ConfirmationModal
        showConfirmModal={showConfirmModal}
        action={handleDelete}
        onSetShowConfirmModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
}

export default ActionButtons;
