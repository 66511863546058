import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import propTypes from "prop-types";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import moment from "moment";

const UserProfileForm = ({
  onChange,
  onUserDetailsChange,
  onSave,
  userProfile,
  userDetails,
  isAdmin,
}) => {
  const [localUserProfile, setLocalUserProfile] = useState({});
  const [localUserDetails, setLocalUserDetails] = useState({});
  const [isArchived, setIsArchived] = useState(userProfile?.isArchived);
  const [age, setAge] = useState();

  useEffect(() => {
    setLocalUserProfile(userProfile);
    setLocalUserDetails(userDetails);
    setIsArchived(userProfile.isArchived);
  }, [userProfile, userDetails]);

  function getUserAge() {
    return moment().diff(localUserDetails?.age, "years");
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Profil użytkownika</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>Imię</label>
                        <Form.Control
                          placeholder="Imię"
                          type="text"
                          name="firstName"
                          onChange={onChange}
                          value={localUserProfile?.firstName}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>Nazwisko</label>
                        <Form.Control
                          placeholder="Nazwisko"
                          type="text"
                          name="lastName"
                          onChange={onChange}
                          value={localUserProfile?.lastName}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>Numer telefonu</label>
                        <Form.Control
                          placeholder="Numer telefonu"
                          type="text"
                          name="phoneNumber"
                          onChange={onChange}
                          value={localUserProfile?.phoneNumber}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>E-mail adres</label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          name="email"
                          onChange={onChange}
                          value={localUserProfile?.email}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>Hasło</label>
                        <Form.Control
                          placeholder="Hasło"
                          name="password"
                          id="password"
                          type="password"
                          onChange={onUserDetailsChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="pr-1" md="2">
                        <div style={{ display: "grid", marginTop: "3px" }}>
                          <label>Data urodzenia</label>
                          <div
                            style={{
                              display: "flex",
                              gap: "35px",
                              alignItems: "center",
                            }}
                          >
                            <DatePicker
                              style={{ width: "220px" }}
                              selected={age}
                              showYearDropdown
                              yearDropdownItemNumber={60}
                              maxDate={new Date()}
                              value={moment(localUserDetails?.age).format(
                                "YYYY-MM-DD"
                              )}
                              name="age"
                              onChange={(e) => {
                                onUserDetailsChange({
                                  target: {
                                    name: "age",
                                    value: e,
                                  },
                                }),
                                  setAge(e);
                              }}
                              className="form-control"
                            />
                            <span>{getUserAge()} lat</span>
                          </div>
                        </div>

                        {/* <Form.Control
                            placeholder="Wiek"
                            type="text"
                            name="age"
                            onChange={onUserDetailsChange}
                            value={localUserDetails?.age}
                          ></Form.Control> */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>Wzrost</label>
                        <Form.Control
                          placeholder="Wzrost (w centymetrach)"
                          type="number"
                          name="height"
                          onChange={onUserDetailsChange}
                          value={localUserDetails?.height}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>Waga</label>
                        <Form.Control
                          placeholder="Waga (w kilogramach)"
                          type="number"
                          name="weight"
                          onChange={onUserDetailsChange}
                          value={localUserDetails?.weight}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="pr-1" md="3">
                        <label>BMI</label>
                        <Form.Control
                          placeholder="BMI"
                          type="number"
                          name="bmi"
                          onChange={onUserDetailsChange}
                          value={localUserDetails?.bmi}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group md="12">
                        <label>
                          Dodatkowe informacje (choroby, przeciwskazania,
                          preferowane dni treningowe itp.)
                        </label>
                        <Form.Control
                          cols="80"
                          placeholder="To możesz umieścić dodatkowe informacje"
                          rows="4"
                          as="textarea"
                          name="additionalInformation"
                          onChange={onUserDetailsChange}
                          value={localUserDetails?.additionalInformation}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {isArchived !== undefined && isAdmin ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Toggle
                            defaultChecked={!isArchived}
                            value={!isArchived}
                            name="isArchived"
                            id="isArchived"
                            onChange={(e) => {
                              onChange(e), setIsArchived((value) => !value);
                            }}
                          />
                          <span>
                            {isArchived
                              ? "Użytkownik zarchiwizowany"
                              : "Użytkownik aktywny"}
                          </span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <Button
                        className="btn-fill"
                        style={{ width: "25%", marginTop: "16px" }}
                        type="button"
                        variant="info"
                        onClick={onSave}
                      >
                        Zaktualizuj profil
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

UserProfileForm.propTypes = {
  userProfile: propTypes.object.isRequired,
  userDetails: propTypes.object.isRequired,
  onSave: propTypes.func.isRequired,
};

export default UserProfileForm;
