import { handleError, handleResponse } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/auth";

export function login(credentials) {
  return fetch(baseUrl + "/authenticate", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(credentials),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function registerUser(form) {
  return fetch(baseUrl + "/register/" + form?.coachId, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(form),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function changeUserPassword(form) {
  return fetch(baseUrl + "/users/password/change", {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(form),
  })
    .then(handleResponse)
    .catch(handleError);
}
