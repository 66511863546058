import Spinner from "react-bootstrap/Spinner";

function Loading({ size }) {
  return (
    <Spinner
      animation="border"
      variant="secondary"
      style={{
        width: size + "rem",
        height: size + "rem",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    ></Spinner>
  );
}

export default Loading;
