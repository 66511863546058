import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { userLogout } from "../../redux/actions/loginActions";
import {
  loadCoachUsers,
  loadUserProfile,
  loadUserDetails,
  loadUserPersonalBest,
  loadUserTrainingInformation,
  setUser,
} from "redux/actions/userActions";
import { setDate } from "redux/actions/coreActions";
import { loadTrainings } from "redux/actions/trainingActions";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";
import DateNavigator from "./DateNavigator";
import { isKosekTeam } from "utils/locationHelper";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { getUserName } from "utils/authHelper";

function Header({
  userLogout,
  loadCoachUsers,
  loadUserProfile,
  loadUserDetails,
  loadUserPersonalBest,
  loadUserTrainingInformation,
  loadTrainings,
  setUser,
  coachUsers,
  selectedUser,
  loggedUser,
  currentDate,
  setDate,
  userInformation,
  loggedUserInformation,
}) {
  const history = useHistory();
  const [selectedUserState, setSelectedUserState] =
    useState("Wybierz zawodnika");
  const [showArchivedUsers, setShowArchivedUsers] = useState(false);

  useEffect(() => {
    if (!IsNullOrEmptyOrUndefined(loggedUser.userId)) {
      loadCoachUsers(loggedUser.userId).catch((error) => {
        console.log("Wystąpił błąd podczas ładowania użytkowników: " + error);
      });
    }

    if (!IsNullOrEmptyOrUndefined(selectedUser)) {
      loadUserProfile(selectedUser).catch((error) => {
        console.log("Wystąpił błąd podczas ładowania profilu: " + error);
      });
      loadTrainings(selectedUser).catch((error) => {
        console.log("Wystąpił błąd podczas ładowania treningów: " + error);
      });
      loadUserDetails(selectedUser).catch((error) => {
        console.log(
          "Wystąpił błąd podczas ładowania detali użytkownika: " + error
        );
      });
      loadUserTrainingInformation(selectedUser).catch((error) => {
        console.log(
          "Wystąpił błąd podczas ładowania informacji treningowych: " + error
        );
      });
      loadUserPersonalBest(selectedUser).catch((error) => {
        console.log(
          "Wystąpił błąd podczas ładowania rekordów życiowych: " + error
        );
      });
    }
  }, [loggedUser, selectedUser]);

  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const handleOptionSelect = (option) => {
    setSelectedUserState(
      option?.email + " (" + option?.firstName + " " + option?.lastName + ")"
    );
    setUser(option);
  };

  const logout = () => {
    userLogout();
    history.push(isKosekTeam() ? "/kosekteam" : "/kreskateam");
  };

  const toggleShowArchivedUsers = (e) => {
    setShowArchivedUsers(e.target.checked);
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {loggedUser?.isAdmin ? (
              <div style={{ display: "flex", gap: "16px" }}>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {selectedUserState}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {coachUsers?.map(
                      (option, index) =>
                        (!option?.isArchived || showArchivedUsers) && (
                          <Dropdown.Item
                            onClick={() => handleOptionSelect(option)}
                            key={index}
                          >
                            {option?.email +
                              " (" +
                              option?.firstName +
                              " " +
                              option?.lastName +
                              ")"}
                          </Dropdown.Item>
                        )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : null}
          </Navbar.Brand>
          {loggedUser.isAdmin ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Toggle
                defaultChecked={false}
                name="showArchivedUsers"
                id="showArchivedUsers"
                onChange={toggleShowArchivedUsers}
              />
              <span style={{ fontSize: "14px" }}>
                {showArchivedUsers
                  ? "Użytkownicy zarchiwizowani widoczni"
                  : "Użytkownicy zarchiwizowani ukryci"}
              </span>
            </div>
          ) : null}
        </div>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle> */}
        {window.location.pathname === "/kosekteam" ||
        window.location.pathname === "/kreskateam" ||
        window.location.pathname === "/" ? (
          <DateNavigator currentDate={currentDate} setCurrentDate={setDate} />
        ) : null}
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontWeight: "700",
            fontFamilyL: "Arial",
          }}
        >
          {loggedUserInformation
            ? `Witaj, ${getUserName(loggedUserInformation)}`
            : ""}
          <i
            onClick={(_) => logout()}
            className="nc-icon nc-button-power"
            style={{
              fontSize: "26px",
              position: "relative",
              bottom: "6px",
              cursor: "pointer",
            }}
          ></i>
        </div>
      </Container>
    </Navbar>
  );
}

Header.propTypes = {
  userLogout: propTypes.func.isRequired,
  loadCoachUsers: propTypes.func.isRequired,
  loadUserProfile: propTypes.func.isRequired,
  loadUserDetails: propTypes.func.isRequired,
  loadUserPersonalBest: propTypes.func.isRequired,
  loadUserTrainingInformation: propTypes.func.isRequired,
  loadTrainings: propTypes.func.isRequired,
  selectedUser: propTypes.number,
  loggedUser: propTypes.object.isRequired,
  userInformation: propTypes.object.isRequired,
  setUser: propTypes.func.isRequired,
  history: propTypes.object,
};

const returnAllAvailableUsers = (coachUsers, loggedUser) => {
  if (coachUsers?.length > 0) {
    let mergedUsers = [...coachUsers, loggedUser];
    return mergedUsers;
  } else {
    return [];
  }
};

function mapStateToProps(state) {
  return {
    userInformation: state.user.userProfile,
    loggedUserInformation: state.user.loggedUserInformation,
    loggedUser: state.auth,
    selectedUser: state.user.selectedUser?.userId || state.auth.userId,
    coachUsers: returnAllAvailableUsers(
      state.user.coachUsers,
      state.user.userProfile
    ),
    currentDate: state.core?.selectedDate,
  };
}

const mapDispatchToProps = {
  userLogout,
  loadCoachUsers,
  loadUserProfile,
  loadUserDetails,
  loadUserPersonalBest,
  loadUserTrainingInformation,
  loadTrainings,
  setUser,
  setDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
